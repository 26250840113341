


// import { useState, useEffect } from "react";
// import { Container, Row, Button, Modal, Form, InputGroup } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import axios from "axios";
// import ProductCard from "../components/ProductCard/ProductCard";
// import { axiosInstance } from "../utils/axios";

// const ProductDashboard = () => {
//   const [products, setProducts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [showEditModal, setShowEditModal] = useState(false);
//   const [updatedProduct, setUpdatedProduct] = useState({});
//   const [searchQuery, setSearchQuery] = useState(""); // For the search functionality
//   const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false); // For delete confirmation
//   const [productToDelete, setProductToDelete] = useState(null); // Product to delete
//   const navigate = useNavigate();

//   // Fetch products whenever the page or search query changes
//   useEffect(() => {
//     fetchProducts();
//   }, [currentPage, searchQuery]);

//   // Fetch products based on the search query and pagination
//   const fetchProducts = async () => {
//     try {
//       const response = await axiosInstance.get(`/users/data/dashboard`, {
//         params: {
//           query: searchQuery,
//           limit: 10,
//           page: currentPage,
//         },
//       });
//       const products = response.data.products;
//       console.log('products',products);
//       setProducts(products);
//       setTotalPages(response.data.totalPages);
//     } catch (error) {
//       setProducts([]);
//     }
//   };

//   // Handle product deletion
//   const handleDelete = async () => {
//     try {
//       if (productToDelete) {
//         await axiosInstance.delete(`/products/${productToDelete.id}`);
//         toast.success("Product deleted successfully!");
//         setProducts(products.filter((product) => product.id !== productToDelete.id));
//         setShowDeleteConfirmModal(false); // Close the modal after deletion
//       }
//     } catch (error) {
//       toast.error("Error deleting product!");
//       setShowDeleteConfirmModal(false); // Close the modal if error occurs
//     }
//   };

//   // Show delete confirmation modal
//   const handleShowDeleteConfirmModal = (product) => {
//     setProductToDelete(product); // Set the product to be deleted
//     setShowDeleteConfirmModal(true); // Show the modal
//   };

//   // Handle product edit and show the edit modal
//   const handleEdit = (product) => {
//     setSelectedProduct(product);
//     setUpdatedProduct(product);
//     setShowEditModal(true);
//   };

//   // Handle the update of product data
//   const handleUpdate = async () => {
//     try {
//       await axiosInstance.put(`/products/${updatedProduct.id}`, updatedProduct);
//       toast.success("Product updated successfully!");
//       setShowEditModal(false);
//       setUpdatedProduct({});
//       setSelectedProduct(null);
//       fetchProducts(); // Re-fetch products after update
//     } catch (error) {
//       toast.error("Error updating product!");
//     }
//   };

//   // Handle changes in the input fields for updating a product
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setUpdatedProduct((prev) => ({ ...prev, [name]: value }));
//   };

//   // Handle search query changes
//   const handleSearch = (e) => {
//     setSearchQuery(e.target.value);
//     setCurrentPage(1); // Reset to page 1 when search query changes
//   };

//   return (
//     <Container>
//       <h2>Dealer  Dashboard</h2>

//       {/* Search Input */}
//       <InputGroup className="mb-4">
//         <Form.Control
//           type="text"
//           placeholder="Search products..."
//           value={searchQuery}
//           onChange={handleSearch}
//         />
//         <Button variant="outline-secondary" onClick={() => fetchProducts()}>
//           Search
//         </Button>
//       </InputGroup>

//       <Row>
//         {products.map((product) => (
//           <ProductCard
//             key={product.id}
//             title="Big Discount"
//             productItem={product}
//             showEditDelete={true}  // Show Edit and Delete buttons
//             onEdit={handleEdit}    // Handle the Edit action
//             onDelete={() => handleShowDeleteConfirmModal(product)} // Handle the Delete action
//           />
//         ))}
//       </Row>

//       {/* Pagination */}
//       <div className="pagination">
//         <Button
//           disabled={currentPage === 1}
//           onClick={() => setCurrentPage(currentPage - 1)}
//         >
//           Previous
//         </Button>
//         <span>
//           Page {currentPage} of {totalPages}
//         </span>
//         <Button
//           disabled={currentPage === totalPages}
//           onClick={() => setCurrentPage(currentPage + 1)}
//         >
//           Next
//         </Button>
//       </div>

//       {/* Product Edit Modal */}
//       {selectedProduct && (
//         <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Edit Product</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <Form>
//               <Form.Group controlId="formProductName">
//                 <Form.Label>Product Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter product name"
//                   name="productName"
//                   value={updatedProduct.productName || ""}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//               <Form.Group controlId="formDescription">
//                 <Form.Label>Description</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter product description"
//                   name="description"
//                   value={updatedProduct.description || ""}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//               <Form.Group controlId="formPrice">
//                 <Form.Label>Price</Form.Label>
//                 <Form.Control
//                   type="number"
//                   placeholder="Enter product price"
//                   name="price"
//                   value={updatedProduct.price || ""}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//               <Form.Group controlId="formQuantity">
//                 <Form.Label>Quantity</Form.Label>
//                 <Form.Control
//                   type="number"
//                   placeholder="Enter product quantity"
//                   name="quantity"
//                   value={updatedProduct.quantity || ""}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//               <Form.Group controlId="formMRP">
//                 <Form.Label>MRP</Form.Label>
//                 <Form.Control
//                   type="number"
//                   placeholder="Enter MRP"
//                   name="mrp"
//                   value={updatedProduct.mrp || ""}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Form>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button variant="secondary" onClick={() => setShowEditModal(false)}>
//               Close
//             </Button>
//             <Button variant="primary" onClick={handleUpdate}>
//               Save Changes
//             </Button>
//           </Modal.Footer>
//         </Modal>
//       )}

//       {/* Delete Confirmation Modal */}
//       <Modal show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Confirm Deletion</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>Are you sure you want to delete this product?</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>
//             Cancel
//           </Button>
//           <Button variant="danger" onClick={handleDelete}>
//             Yes, Delete
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </Container>
//   );
// };

// export default ProductDashboard;






















// import { useState, useEffect } from "react";
// import { Container, Row, Button, Modal, Form, InputGroup } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import axios from "axios";
// import ProductCard from "../components/ProductCard/ProductCard";
// import { axiosInstance } from "../utils/axios";

// const ProductDashboard = () => {
//   const [products, setProducts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [showEditModal, setShowEditModal] = useState(false);
//   const [updatedProduct, setUpdatedProduct] = useState({});
//   const [searchQuery, setSearchQuery] = useState("");
//   const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
//   const [productToDelete, setProductToDelete] = useState(null);
//   const [showAddProductModal, setShowAddProductModal] = useState(false); // New modal for adding a product
//   const [productType, setProductType] = useState("fresh"); // Track whether fresh or offer product
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchProducts();
//   }, [currentPage, searchQuery]);

//   const fetchProducts = async () => {
//     try {
//       const response = await axiosInstance.get(`/users/data/dashboard`, {
//         params: {
//           query: searchQuery,
//           limit: 10,
//           page: currentPage,
//         },
//       });
//       const products = response.data.products;
//       setProducts(products);
//       setTotalPages(response.data.totalPages);
//     } catch (error) {
//       setProducts([]);
//     }
//   };

//   const handleDelete = async () => {
//     try {
//       if (productToDelete) {
//         await axiosInstance.delete(`/products/${productToDelete.id}`);
//         toast.success("Product deleted successfully!");
//         setProducts(products.filter((product) => product.id !== productToDelete.id));
//         setShowDeleteConfirmModal(false);
//       }
//     } catch (error) {
//       toast.error("Error deleting product!");
//       setShowDeleteConfirmModal(false);
//     }
//   };

//   const handleShowDeleteConfirmModal = (product) => {
//     setProductToDelete(product);
//     setShowDeleteConfirmModal(true);
//   };

//   const handleEdit = (product) => {
//     setSelectedProduct(product);
//     setUpdatedProduct(product);
//     setShowEditModal(true);
//   };

//   const handleUpdate = async () => {
//     try {
//       await axiosInstance.put(`/products/${updatedProduct.id}`, updatedProduct);
//       toast.success("Product updated successfully!");
//       setShowEditModal(false);
//       setUpdatedProduct({});
//       setSelectedProduct(null);
//       fetchProducts();
//     } catch (error) {
//       toast.error("Error updating product!");
//     }
//   };

//   const handleAddProduct = (productData) => {
//     // This function can be used to send the new product data to your API.
//     toast.success("Product added successfully!");
//     setShowAddProductModal(false);
//     fetchProducts();
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setUpdatedProduct((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleSearch = (e) => {
//     setSearchQuery(e.target.value);
//     setCurrentPage(1);
//   };

//   const handleAddProductType = (type) => {
//     setProductType(type); // This will toggle between "fresh" and "offer" product types
//     setShowAddProductModal(true);
//   };

//   return (
//     <Container>
//       <h2>Dealer Dashboard</h2>

//     {/* Buttons for adding products */}
//       <div className="mb-4">
//         <Button 
//           variant="primary" 
//           onClick={() => handleAddProductType("fresh")}
//           className="mr-3" // Adds margin to the right of the button
//         >
//           Add Fresh Product
//         </Button>
//         <Button 
//           variant="warning" 
//           onClick={() => handleAddProductType("offer")}
//         >
//           Add Offer Product
//         </Button>
//       </div>


//       <InputGroup className="mb-4">
//         <Form.Control
//           type="text"
//           placeholder="Search products..."
//           value={searchQuery}
//           onChange={handleSearch}
//         />
//         <Button variant="outline-secondary" onClick={() => fetchProducts()}>
//           Search
//         </Button>
//       </InputGroup>

//       <Row>
//         {products.map((product) => (
//           <ProductCard
//             key={product.id}
//             title="Big Discount"
//             productItem={product}
//             showEditDelete={true}
//             onEdit={handleEdit}
//             onDelete={() => handleShowDeleteConfirmModal(product)}
//           />
//         ))}
//       </Row>

//       {/* Pagination */}
//       <div className="pagination">
//         <Button
//           disabled={currentPage === 1}
//           onClick={() => setCurrentPage(currentPage - 1)}
//         >
//           Previous
//         </Button>
//         <span>
//           Page {currentPage} of {totalPages}
//         </span>
//         <Button
//           disabled={currentPage === totalPages}
//           onClick={() => setCurrentPage(currentPage + 1)}
//         >
//           Next
//         </Button>
//       </div>

//       {/* Add Product Modal */}
//       <Modal show={showAddProductModal} onHide={() => setShowAddProductModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>{productType === "fresh" ? "Add Fresh Product" : "Add Offer Product"}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId="formProductName">
//               <Form.Label>Product Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter product name"
//                 name="productName"
//                 onChange={handleChange}
//               />
//             </Form.Group>
//             <Form.Group controlId="formDescription">
//               <Form.Label>Description</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter product description"
//                 name="description"
//                 onChange={handleChange}
//               />
//             </Form.Group>
//             <Form.Group controlId="formPrice">
//               <Form.Label>Price</Form.Label>
//               <Form.Control
//                 type="number"
//                 placeholder="Enter product price"
//                 name="price"
//                 onChange={handleChange}
//               />
//             </Form.Group>
//             <Form.Group controlId="formQuantity">
//               <Form.Label>Quantity</Form.Label>
//               <Form.Control
//                 type="number"
//                 placeholder="Enter product quantity"
//                 name="quantity"
//                 onChange={handleChange}
//               />
//             </Form.Group>

//             {/* Conditional field for offer products */}
//             {productType === "offer" && (
//               <Form.Group controlId="formOfferDiscount">
//                 <Form.Label>Offer Discount</Form.Label>
//                 <Form.Control
//                   type="number"
//                   placeholder="Enter offer discount"
//                   name="offerDiscount"
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             )}
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowAddProductModal(false)}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={() => handleAddProduct(updatedProduct)}>
//             Save Product
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {/* Edit and Delete modals are similar to what you already had */}
//     </Container>
//   );
// };

// export default ProductDashboard;






import { useState, useEffect } from "react";
import { Container, Row, Button, InputGroup, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../utils/axios";
import ProductCard from "../components/ProductCard/ProductCard";
import AddProductModal from "../components/ProductCard/AddProductModal";
import EditProductModal from "../components/ProductCard/EditProductModal";
import DeleteProductModal from "../components/ProductCard/DeleteProductModal";

const ProductDashboard = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updatedProduct, setUpdatedProduct] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [productType, setProductType] = useState("fresh"); // Default type is "fresh"
  const [productData, setProductData] = useState({}); // For holding the data for new product

  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts('fresh'); // Fetch products whenever currentPage, searchQuery, or productType changes
  }, [currentPage, searchQuery]);

  const fetchProducts = async (type) => {
    try {
      // Fetch products based on current page, search query, and product type (fresh or offer)
      const response = await axiosInstance.get(`/users/data/dashboard`, {
        params: { 
          query: searchQuery, 
          limit: 10, 
          page: currentPage, 
          type: type // Pass the productType (fresh or offer)
        },
      });
      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      setProducts([]);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page whenever search query changes
  };

  const handleAddProductType = (type) => {
    setProductType(type); // Update product type based on button clicked
    setShowAddProductModal(true); // Show modal to add a product
  };

  const handleShowDeleteConfirmModal = (product) => {
        setProductToDelete(product);
        setShowDeleteConfirmModal(true);
      };

  const handleEdit = (product) => {
        setSelectedProduct(product);
        setUpdatedProduct(product);
        setShowEditModal(true);
      };


      const handleSuccessUpdate = (updatedProductData) => {
        fetchProducts('fresh')
      };
      const handleSuccessDelete = (productId) => {
        fetchProducts('fresh')
      };

  return (
    <Container>
      <h2>Dealer Dashboard</h2>

      <div className="d-flex mb-10 mt-5">
        <Button
          variant="primary"
          onClick={() => handleAddProductType("fresh")}
          className="mr-3"
        >
          Add Fresh Product
        </Button>
        <Button
          variant="warning"
          onClick={() => handleAddProductType("offer")}
          className="mr-3"
        >
          Add Offer Product
        </Button>
        <Button
          variant="primary"
          onClick={() => fetchProducts("fresh")}
          className="mr-3"
        >
          Get Fresh Product
        </Button>
        <Button
          variant="warning"
          onClick={() => fetchProducts("offer")}
        >
          Get Offer Product
        </Button>
      </div>

      <InputGroup className="mb-4">
        <Form.Control
          type="text"
          placeholder="Search products..."
          value={searchQuery}
          onChange={handleSearch}
        />
        <Button variant="outline-secondary" onClick={() => fetchProducts()}>
          Search
        </Button>
      </InputGroup>

      <Row>
        {products.map((product) => (
          <ProductCard
            key={product.id}
            title="Big Discount"
            productItem={product}
            showEditDelete={true}
            onEdit={handleEdit}
            onDelete={() => handleShowDeleteConfirmModal(product)}
          />
        ))}
      </Row>

      <div className="pagination">
        <Button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </Button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <Button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </Button>
      </div>

      <AddProductModal
        show={showAddProductModal}
        handleClose={() => setShowAddProductModal(false)}
        // handleSave={handleSaveProduct}
        productType={productType}
        productData={productData}
        setProductData={setProductData}
      />
      {/* EditProductModal for editing product */}
      <EditProductModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        productData={updatedProduct}
        setProductData={setProductData}
        handleSuccessUpdate={handleSuccessUpdate} 
      />

      {/* Delete Confirmation Modal */}
      <DeleteProductModal
        show={showDeleteConfirmModal}
        handleClose={() => setShowDeleteConfirmModal(false)} // Close the modal
        productToDelete={productToDelete} // Pass the product to delete
        handleSuccessDelete={handleSuccessDelete} // Callback after successful deletion
      />

    </Container>
  );
};

export default ProductDashboard;
