
// import React, { useState, useEffect } from 'react';
// import { Modal, Button, Form } from 'react-bootstrap';

// const AddProductModal = ({ show, handleClose, handleSave, productType, productData, setProductData }) => {
//   const [image, setImage] = useState(null);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProductData((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setImage(URL.createObjectURL(file));
//       setProductData((prev) => ({ ...prev, image: file }));
//     }
//   };

//   const handleRemoveImage = () => {
//     setImage(null);
//     setProductData((prev) => ({ ...prev, image: null }));
//   };

//   useEffect(() => {
//     if (productData.image) {
//       setImage(URL.createObjectURL(productData.image));
//     }
//   }, [productData.image]);

//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>{productType === 'fresh' ? 'Add Fresh Product' : 'Add Offer Product'}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Form>
//           <Form.Group controlId="formProductName">
//             <Form.Label>Product Name</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter product name"
//               name="productName"
//               value={productData.productName || ''}
//               onChange={handleChange}
//             />
//           </Form.Group>
//           <Form.Group controlId="formDescription">
//             <Form.Label>Description</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter product description"
//               name="description"
//               value={productData.description || ''}
//               onChange={handleChange}
//             />
//           </Form.Group>
//           <Form.Group controlId="formPrice">
//             <Form.Label>Price</Form.Label>
//             <Form.Control
//               type="number"
//               placeholder="Enter product price"
//               name="price"
//               value={productData.price || ''}
//               onChange={handleChange}
//             />
//           </Form.Group>
//           <Form.Group controlId="formQuantity">
//             <Form.Label>Quantity</Form.Label>
//             <Form.Control
//               type="number"
//               placeholder="Enter product quantity"
//               name="quantity"
//               value={productData.quantity || ''}
//               onChange={handleChange}
//             />
//           </Form.Group>

//           {/* Conditional field for offer products */}
//           {productType === 'offer' && (
//             <Form.Group controlId="formOfferDiscount">
//               <Form.Label>Offer Discount</Form.Label>
//               <Form.Control
//                 type="number"
//                 placeholder="Enter offer discount"
//                 name="offerDiscount"
//                 value={productData.offerDiscount || ''}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           )}

//           {/* Image upload section */}
//           <Form.Group controlId="formImage">
//             <Form.Label>Product Image</Form.Label>
//             <Form.Control
//               type="file"
//               accept="image/*"
//               onChange={handleImageChange}
//             />
//             {image && (
//               <div className="mt-3">
//                 <img src={image} alt="Selected" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
//                 <Button variant="danger" size="sm" onClick={handleRemoveImage}>Remove Image</Button>
//               </div>
//             )}
//           </Form.Group>
//         </Form>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//         <Button variant="primary" onClick={() => handleSave(productData)}>
//           Save Product
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default AddProductModal;



import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { axiosInstance } from '../../utils/axios';
import { toast } from 'react-toastify';

const AddProductModal = ({ show, handleClose, productType, setMessage, setMessageType }) => {
  const [productData, setProductData] = useState({
    productName: '',
    quantity: '',
    description: '',
    category: '',
    price: '',
    mrp: '',
    productType: productType || 'fresh', // Default to 'fresh'
    image: null,
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {

    if (productData.image) {
      setImagePreview(URL.createObjectURL(productData.image));
    } else {
      setImagePreview(null);
    }
  }, [productData.image]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProductData((prev) => ({ ...prev, image: file }));
    }
  };

  const handleRemoveImage = () => {
    setProductData((prev) => ({ ...prev, image: null }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('jpore',productData)

    // Validation before submitting
    if (!productData.productName || !productData.quantity || !productData.description || !productData.category || !productData.price || !productData.mrp || !productData.image) {
      alert('all field required')
      return;
    }

    setIsSubmitting(true);

    // Create FormData to send the data
    const formData = new FormData();
    formData.append('productName', productData.productName);
    formData.append('quantity', productData.quantity);
    formData.append('description', productData.description);
    formData.append('category', productData.category);
    formData.append('price', productData.price);
    formData.append('mrp', productData.mrp);
    formData.append('image', productData.image);
    formData.append('productType', productType);  // Adding productType to FormData

    // If productType is 'offer', we assume backend handles that type (no additional fields required)
    // If you want to add other fields specific to 'offer', they can be added here (e.g., discount, etc.)

    try {
      const response = await axiosInstance.post('/products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        toast.success(response.data.message);
        setProductData({
          productName: '',
          quantity: '',
          description: '',
          category: '',
          price: '',
          mrp: '',
          productType: '',  // Reset to 'fresh' after submission
          image: null,
        });
        setImagePreview(null); // Clear image preview
        handleClose(); // Close modal after submission
      }
    }  catch (error) {
      console.error('Error submitting form:', error);
  
      // Check if error response has a message
      if (error.response && error.response.data && error.response.data.message) {
        // Show the message from backend error response
        alert(error.response.data.message);
      } else {
        // Default error message
        alert('An unexpected error occurred. Please try again.');
      }
    }finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{productType === 'fresh' ? 'Add Fresh Product' : 'Add Offer Product'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formProductName">
            <Form.Label>Product Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product name"
              name="productName"
              value={productData.productName || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product description"
              name="description"
              value={productData.description || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formDescription">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product category"
              name="category"
              value={productData.category || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formPrice">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter product price"
              name="price"
              value={productData.price || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formPrice">
            <Form.Label>Mrp</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter product price"
              name="mrp"
              value={productData.mrp || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formQuantity">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter product quantity"
              name="quantity"
              value={productData.quantity || ''}
              onChange={handleChange}
            />
          </Form.Group>

          {/* Conditional fields for 'offer' product type */}
          {productData.productType === 'offer' && (
            <Form.Group controlId="formOfferDiscount">
              <Form.Label>Offer Discount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter offer discount"
                name="offerDiscount"
                value={productData.offerDiscount || ''}
                onChange={handleChange}
              />
            </Form.Group>
          )}

          {/* Image upload section */}
          <Form.Group controlId="formImage">
            <Form.Label>Product Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            {imagePreview && (
              <div className="mt-3">
                <img src={imagePreview} alt="Selected" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                <Button variant="danger" size="sm" onClick={handleRemoveImage}>Remove Image</Button>
              </div>
            )}
          </Form.Group>

          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Save Product'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddProductModal;
