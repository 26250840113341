




import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { axiosInstance } from "../utils/axios"; // assuming you have this setup for your API requests
import { useNavigate } from 'react-router-dom';

const QuerySubmit = () => {
  const navigate = useNavigate();  // Initialize useNavigate
  // State for managing query input, location, and product details
  const [query, setQuery] = useState('');
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showNoProductsMessage, setShowNoProductsMessage] = useState(false); // State for no products message

  // Fetch user's location on component mount
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error fetching geolocation:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  // Handle form input change for the query
  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  // Handle product details change
  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!query.trim()) {
      toast.error("Please enter a query.");
      return;
    }

    if (!location.latitude || !location.longitude) {
      toast.error("Unable to fetch location.");
      return;
    }

    // If no product is available, we show a message and let user fill in details
    if (!productName || !price || !description) {
      setShowNoProductsMessage(true);
      toast.error("Please fill in the product details.");
      return;
    }

    // Start loading
    setIsLoading(true);

    try {
      const response = await axiosInstance.post('/query/submit', {
        query,
        latitude: location.latitude,
        longitude: location.longitude,
        productName,
        price,
        description,
      });

      if (response.data.message) {
        toast.success(response.data.message);
        setQuery('');
        setProductName('');
        setPrice('');
        setDescription('');
         // After successful submission, redirect to the home page
         navigate('/'); // Redirect to home page (or any other page you prefer)
        
      } else {
        toast.error('Failed to submit your query. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting query:', error);
      toast.error('An error occurred while submitting your query.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto my-8 p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-6 text-center">Submit Your Query</h2>

      {/* Show a message if no product is available */}
      {/* {showNoProductsMessage && ( */}
          <div className="bg-yellow-100 text-yellow-800 p-4 mb-4 rounded-md">
      <p className="font-medium">Oops! No products found for your search.</p>
      <p>Please provide the product details below so we can help you find the perfect match.</p>
      <p className="text-sm text-yellow-700">Enter more specific information, such as product name, category, or features to refine your search.</p>
    </div>

      {/* )} */}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Query Input */}
        <div>
          <label htmlFor="query" className="block text-gray-700">Your Query</label>
          <textarea
            id="query"
            name="query"
            value={query}
            onChange={handleQueryChange}
            rows="4"
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>

        {/* Product Details */}
        <div>
          <label htmlFor="productName" className="block text-gray-700">Product Name</label>
          <input
            type="text"
            id="productName"
            name="productName"
            value={productName}
            onChange={handleProductNameChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>

        <div>
          <label htmlFor="price" className="block text-gray-700">Price</label>
          <input
            type="number"
            id="price"
            name="price"
            value={price}
            onChange={handlePriceChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>

        <div>
          <label htmlFor="description" className="block text-gray-700">Description</label>
          <textarea
            id="description"
            name="description"
            value={description}
            onChange={handleDescriptionChange}
            rows="4"
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>

        {/* Display User's Geolocation */}
        {/* <div className="text-sm text-gray-500">
          {location.latitude && location.longitude ? (
            <p>
              Location: Latitude: {location.latitude}, Longitude: {location.longitude}
            </p>
          ) : (
            <p>Geolocation not available</p>
          )}
        </div> */}

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="spinner-border spinner-border-sm"></span> // Loader
            ) : (
              'Submit Query'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default QuerySubmit;

