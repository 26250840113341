import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../utils/axios';

const DeleteProductModal = ({ show, handleClose, productToDelete, handleSuccessDelete }) => {

  const handleDelete = async () => {
    try {
      if (productToDelete) {
        // Make the API call to delete the product
        await axiosInstance.delete(`/products/${productToDelete.id}`);
        
        // Success toast notification
        toast.success('Product deleted successfully!');
        
        // Pass the deleted product's id to the parent via callback
        handleSuccessDelete(productToDelete.id);
        
        // Close the modal
        handleClose();
      }
    } catch (error) {
      toast.error('Error deleting product!');
      handleClose(); // Close the modal even if there was an error
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this product?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Yes, Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteProductModal;
