// import React from "react";
// import { AiOutlineCheckCircle } from "react-icons/ai";  // Importing React Icon for success

// const SuccessModal = ({title, message, onClose }) => {
//   return (
//     <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
//       <div className="bg-white rounded-lg shadow-lg p-6 w-80 text-center">
//         <div className="flex justify-center mb-4">
//           <div className="bg-green-100 rounded-full p-4">
//             <AiOutlineCheckCircle className="h-8 w-8 text-green-500" />
//           </div>
//         </div>
//         <h2 className="text-lg font-semibold text-gray-800 mb-2">{title}</h2>
//         <p className="text-gray-600 mb-6">{message}</p>
//         <button
//           onClick={onClose}
//           className="bg-[#e2f2b2] hover:text-white  text-black hover:bg-[#0a925a]  px-4 py-2 rounded-lg  transition"
//         >
//           OK
//         </button>
//       </div>
//     </div>
//   );
// };

// export default SuccessModal;





import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai"; // Importing React Icon for success
import { useNavigate } from "react-router-dom"; // Import useNavigate

const SuccessModal = ({ title, message, onClose }) => {
  const navigate = useNavigate(); // Initialize navigation

  const handleOkClick = () => {
    onClose(); // Close the modal first
    navigate("/admin/dashboard"); // Navigate to the home page
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-80 text-center">
        <div className="flex justify-center mb-4">
          <div className="bg-green-100 rounded-full p-4">
            <AiOutlineCheckCircle className="h-8 w-8 text-green-500" />
          </div>
        </div>
        <h2 className="text-lg font-semibold text-gray-800 mb-2">{title}</h2>
        <p className="text-gray-600 mb-6">{message}</p>
        <button
          onClick={handleOkClick}
          className="bg-[#e2f2b2] hover:text-white text-black hover:bg-[#0a925a] px-4 py-2 rounded-lg transition"
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
