// import { lazy, Suspense } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import NavBar from "./components/Navbar/Navbar";
// import Footer from "./components/Footer/Footer";
// import Loader from "./components/Loader/Loader";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import RegistrationForm from "./pages/Registration";
// import QuerySubmit from "./pages/QuerySubmit";
// import StoreProduct from "./pages/StoreProduct";
// import LoginComponent from "./pages/Login";
// import ProductDashboard from "./pages/ProductDashboard";
// const Home = lazy(() => import("./pages/Home"));
// const Shop = lazy(() => import("./pages/Shop"));
// const Cart = lazy(() => import("./pages/Cart"));
// const Product = lazy(() => import("./pages/Product"));
// function App() {
//   return (
//     <Suspense fallback={<Loader />}>
//       <Router>
//         <ToastContainer
//           position="top-right"
//           autoClose={1000}
//           hideProgressBar={false}
//           newestOnTop={false}
//           closeOnClick
//           pauseOnFocusLoss
//           draggable
//           pauseOnHover
//           theme="light"
//         />
//         <NavBar />
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/shop" element={<Shop />} />
//           <Route path="/shop/:id" element={<Product />} />
//           <Route path="/cart" element={<Cart />} />
//           <Route path="/register" element={<RegistrationForm />} />
//           <Route path="/product-upload" element={<StoreProduct />} />
//           <Route path="/query" element={<QuerySubmit />} />
//           <Route path="/login" element={<LoginComponent />} />
//           <Route path="/dashboard" element={<ProductDashboard />} />
//         </Routes>
//         <Footer />
//       </Router>
//     </Suspense>
//   );
// }

// export default App;















import { lazy, Suspense, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegistrationForm from "./pages/Registration";
import QuerySubmit from "./pages/QuerySubmit";
import StoreProduct from "./pages/StoreProduct";
import LoginComponent from "./pages/Login";
import ProductDashboard from "./pages/ProductDashboard";
import TermsAndConditions from "./pages/TermsAndConditions";
import AdminLoginComponent from "./pages/adminLogin";
import AdminDashboard from "./pages/AdminDashboard";
import UserLoginComponent from "./pages/userLogin";
import AdminProfile from "./pages/AdminProfile";
import UserProfile from "./pages/UserProfile";
import { requestFCMToken } from "./utils/firebaseUtils";
import { updateSingleAdminDetails, updateSingleBuyerDetails } from "./utils/apiCall";
const Home = lazy(() => import("./pages/Home"));
const Shop = lazy(() => import("./pages/Shop"));
const Cart = lazy(() => import("./pages/Cart"));
const Product = lazy(() => import("./pages/Product"));

function App() {

   const [fcmToken,setFcmToken] =useState(null);

   useEffect(()=>{
    fetchFCMToken();
   },[])

   const storeInBuyerTable=async(token)=>{
    await updateSingleBuyerDetails({ fcmToken: token });
   }

   const storeinAdminTable=async(token)=>{
    const result2 = await updateSingleAdminDetails({fcmToken : token})
    console.log('result 2',result2)
   }

   const fetchFCMToken = async () =>{
    try{
           const token = await requestFCMToken();
           if(token){
             storeInBuyerTable(token)
             storeinAdminTable(token)
           }
           console.log(token)
           setFcmToken(token);
    }catch(error){
          console.log('error getting token',error)
    }
  }




  // Service worker registration (in main entry file, e.g., index.js)
// Register Service Worker

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker successfully registered:', registration);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

  return (
    <div
      className="bg-[#d6e5fb]"
    >
    <Router> {/* Ensure the Router wraps all components */}
      <Suspense fallback={<Loader />}>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <MainContent />
      </Suspense>
    </Router>
    </div>
  );
}

// Separate component for conditionally rendering NavBar and Footer
const MainContent = () => {
  const location = useLocation(); // Get current route


  const isDashboardRoute = location.pathname === "/adminlogin"; // Check if on /dashboard route
  const isLoginPage = location.pathname === "/user/login";
  // console.log(isLoginPage)
  return (
    <>
      {/* Conditionally render NavBar */}
      {!isDashboardRoute && !isLoginPage&& <NavBar />} 
      {/* Main content */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/:id" element={<Product />} />
        <Route path="/cart" element={<Cart />} />
        {/* <Route path="/register" element={<RegistrationForm />} /> */}
        <Route path="/product-upload" element={<StoreProduct />} />
        <Route path="/query" element={<QuerySubmit />} />
        <Route path="/login" element={<UserLoginComponent />} />
        <Route path="/user/login" element={<UserLoginComponent />} />
        <Route path="/admin/login" element={<AdminLoginComponent />} />
        <Route path="/admin/dashboard" element={<AdminDashboard/>} />
        <Route path="/admin/profile" element={<AdminProfile/>} />
        <Route path="/dashboard" element={<ProductDashboard />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} /> 
       {/* user details   */} 
         <Route path="/user/profile" element={<UserProfile/>} />
      </Routes>
      {/* Conditionally render Footer */}
      {!isDashboardRoute && <Footer />} {/* Hide Footer on /dashboard */}
    </>
  );
};

export default App;
