import React from "react";
import { Modal, Button } from "react-bootstrap";

const LocationPermissionModal = ({ show, handleClose,handleRetry }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Location Permission Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        To find nearby products, please turn on your location. 
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {/* <Button variant="primary" onClick={handleRetry}>
          Retry
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default LocationPermissionModal;
