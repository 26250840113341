import {initializeApp} from 'firebase/app';
import {getMessaging, getToken} from 'firebase/messaging'

const firebaseConfig = {
    apiKey: "AIzaSyCIiSrLeRlHlv5VKhQm-LNqBLG3by6nFHg",
    authDomain: "ratefry-ad76f.firebaseapp.com",
    projectId: "ratefry-ad76f",
    storageBucket: "ratefry-ad76f.firebasestorage.app",
    messagingSenderId: "408098317933",
    appId: "1:408098317933:web:1240901e06b714945e8164",
    measurementId: "G-KW0JRHBH3S"
  };

  const vapidKey = 'BPvWojFYxUlNgXMNq8qNptfS8_ZT5VnVDahh7DwNRf7hhPGTGeVBuDOo_koEANiFnWz09o6dpjTvLIszjQAzuM4';

  const app = initializeApp(firebaseConfig)

  const messaging = getMessaging(app);

  export const requestFCMToken = async()=>{
    return Notification.requestPermission()
    .then((permission) =>{
        if(permission === 'granted'){
        return getToken(messaging,{vapidKey})
    }else{
        alert('please enable notification service')
        throw new Error('notification not granted')
    }
    })
    .catch((err) => {
        console.log('Error getting FCM Token:',err)
    })
  }