// import React, { useEffect, useState } from "react";
// import { FiDollarSign } from "react-icons/fi";
// import { axiosInstance } from "../../utils/axios";

// // Sample data for sellers with wallet amount
// const sellers = [
//   { id: 1, firstName: "Viram", lastName: "Singh", mobile: "8824484994", walletAmount: 10 },
//   { id: 2, firstName: "John", lastName: "Doe", mobile: "1234567891", walletAmount: 0 },
// ];

// const SellersList = () => {

//   const [currentPage, setCurrentPage] = useState(1);
//   const sellersPerPage = 5; // Number of sellers to display per page

//   const[data,setData] =useState([]);

//   const AddMoneyToWallet = async(item)=>{
//       try{
//            const response = await axiosInstance.post('/transaction', {
//             name : item.firstName,mobile: item.mobile,userId:item.id
//           });
//           console.log('response is here',response);
//           window.location.href = response.data.redirectUrl; // Redirect to payment gateway
//       }catch(error){
//            console.log('error while fetching sellers',error)
//       }
//   }

  
//   const fetchAllSellers = async()=>{
//     try{
//          const response = await axiosInstance.get('/all/sellers');
//          setData(response?.data?.data)
//     }catch(error){
//          console.log('error while fetching sellers',error)
//     }
// }

// useEffect(()=>{
//     fetchAllSellers();
// },[])


//   // Calculate total pages
//   const totalPages = Math.ceil(sellers.length / sellersPerPage);

//   // Function to handle pagination
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   // Slice the sellers array to get sellers for the current page
//   const currentSellers = sellers.slice(
//     (currentPage - 1) * sellersPerPage,
//     currentPage * sellersPerPage
//   );

//   return (
//     <div className="bg-white rounded-lg shadow-lg p-6">
//       <h3 className="text-2xl font-semibold text-gray-800 mb-6">Dealers List</h3>

//       {/* User List */}
//       <div className="overflow-x-auto">
//         <table className="min-w-full table-auto">
//           <thead>
//             <tr className="bg-gray-100">
//               <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">First Name</th>
//               <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">Last Name</th>
//               <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">Mobile</th>
//               <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">Wallet</th>
//               <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data.map((seller) => (
//               <tr key={seller.id} className="border-b hover:bg-gray-50">
//                 <td className="py-3 px-4 text-sm text-gray-700">{seller.firstName}</td>
//                 <td className="py-3 px-4 text-sm text-gray-700">{seller.lastName}</td>
//                 <td className="py-3 px-4 text-sm text-gray-700">{seller.mobile}</td>
//                 <td className="py-3 px-4 text-sm text-gray-700">{seller.walletAmount}</td>
//                 <td className="py-3 px-4 text-sm">
//                   {seller?.Wallet?.amount < 1 && (
//                     <button
//                     onClick={()=>{
//                         AddMoneyToWallet(seller)
//                     }}                   
//                        className="px-4 py-2 bg-[#e2f2b2]  rounded-lg flex items-center gap-2 hover:bg-[#22a722] hover:text-white !important transition-all duration-300 ease-in-out"
//                     >
//                       <span>Payment</span>
//                     </button>

//                   )}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {/* Pagination */}
//       <div className="mt-6 flex justify-center gap-4">
//         <button
//           onClick={() => paginate(currentPage - 1)}
//           disabled={currentPage === 1}
//           className="px-5 py-2 bg-gray-300 text-gray-700 rounded-lg disabled:opacity-50 hover:bg-gray-400 transition-all duration-300 ease-in-out"
//         >
//           Previous
//         </button>
//         <span className="px-4 py-2 text-lg font-semibold text-gray-700">{currentPage}</span>
//         <button
//           onClick={() => paginate(currentPage + 1)}
//           disabled={currentPage === totalPages}
//           className="px-5 py-2 bg-gray-300 text-gray-700 rounded-lg disabled:opacity-50 hover:bg-gray-400 transition-all duration-300 ease-in-out"
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default SellersList;








// import React, { useEffect, useState } from "react";
// import { FiDollarSign } from "react-icons/fi";
// import { axiosInstance } from "../../utils/axios";

// // SellersList with pagination logic
// const SellersList = () => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [sellersPerPage] = useState(5);
//   const [data, setData] = useState([]);
//   const [totalPages, setTotalPages] = useState(1);

//   // Function to handle redirection to payment gateway
//   const AddMoneyToWallet = async (item) => {
//     try {
//       const response = await axiosInstance.post('/transaction', {
//         name: item.firstName,
//         mobile: item.mobile,
//         userId: item.id,
//       });
//       console.log('response is here', response);
//       window.location.href = response.data.redirectUrl; // Redirect to payment gateway
//     } catch (error) {
//       console.log('error while processing payment', error);
//     }
//   };

//   const fetchAllSellers = async () => {
//     try {
//       const response = await axiosInstance.get('/all/sellers', {
//         params: {
//           page:currentPage,
//           size: sellersPerPage,
//         },
//       });

//       setData(response?.data?.data || []);
//       setTotalPages(response?.data?.pagination?.totalPages || 1);
//     } catch (error) {
//       console.log('error while fetching sellers', error);
//     }
//   };

//   useEffect(() => {
//     fetchAllSellers();
//   }, [currentPage]); // Trigger data fetch on currentPage change

//   // Handle pagination click
//   const paginate = (pageNumber) => {
//     if (pageNumber >= 1 && pageNumber <= totalPages) {
//       setCurrentPage(pageNumber);
//     }
//   };

//   return (
//     <div className="bg-white rounded-lg shadow-lg p-6">
//       <h3 className="text-2xl font-semibold text-gray-800 mb-6">Dealers List</h3>

//       {/* User List */}
//       <div className="overflow-x-auto">
//         <table className="min-w-full table-auto">
//           <thead>
//             <tr className="bg-gray-100">
//               <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">First Name</th>
//               <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">Last Name</th>
//               <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">Mobile</th>
//               <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">Wallet</th>
//               <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data.map((seller) => (
//               <tr key={seller.id} className="border-b hover:bg-gray-50">
//                 <td className="py-3 px-4 text-sm text-gray-700">{seller.firstName}</td>
//                 <td className="py-3 px-4 text-sm text-gray-700">{seller.lastName}</td>
//                 <td className="py-3 px-4 text-sm text-gray-700">{seller.mobile}</td>
//                 <td className="py-3 px-4 text-sm text-gray-700">{seller.walletAmount || 0}</td>
//                 <td className="py-3 px-4 text-sm">
//                   {seller.Wallet.amount < 1 && (
//                     <button
//                       onClick={() => AddMoneyToWallet(seller)}
//                       className="px-4 py-2 bg-[#e2f2b2] rounded-lg flex items-center gap-2 hover:bg-[#22a722] hover:text-white transition-all duration-300 ease-in-out"
//                     >
//                       <span>Payment</span>
//                     </button>
//                   )}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {/* Pagination */}
//       <div className="mt-6 flex justify-center gap-4">
//         <button
//           onClick={() => paginate(currentPage - 1)}
//           disabled={currentPage === 1}
//           className="px-5 py-2 bg-gray-300 text-gray-700 rounded-lg disabled:opacity-50 hover:bg-gray-400 transition-all duration-300 ease-in-out"
//         >
//           Previous
//         </button>
//         <span className="px-4 py-2 text-lg font-semibold text-gray-700">{currentPage}</span>
//         <button
//           onClick={() => paginate(currentPage + 1)}
//           disabled={currentPage === totalPages}
//           className="px-5 py-2 bg-gray-300 text-gray-700 rounded-lg disabled:opacity-50 hover:bg-gray-400 transition-all duration-300 ease-in-out"
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default SellersList;







// import React, { useEffect, useState } from "react";
// import { axiosInstance } from "../../utils/axios";

// const SellersList = () => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [sellersPerPage] = useState(1);
//   const [data, setData] = useState([]);
//   const [totalPages, setTotalPages] = useState(1);

//   const [searchFirstName, setSearchFirstName] = useState("");
//   const fetchSellers = async (page = 1) => {
//     try {
//       const response = await axiosInstance.get("/all/sellers", {
//         params: {
//           page :currentPage,
//           size: sellersPerPage,
//           search: searchFirstName,
//         },
//       });

//       setData(response?.data?.data || []);
//       setTotalPages(response?.data?.pagination?.totalPages || 1);
//     } catch (error) {
//       console.log("Error fetching sellers", error);
//     }
//   };

//   useEffect(() => {
//     fetchSellers();
//   }, [currentPage, searchFirstName]); // Fetch data on pagination or search change

//   const handleSearch = () => {
//     setCurrentPage(1); // Reset to first page when a new search is initiated
//     fetchSellers();
//   };

//   const handlePagination = (pageNumber) => {
//     if (pageNumber >= 1 && pageNumber <= totalPages) {
//       setCurrentPage(pageNumber);
//     }
//   };

//   return (
//     <div className="bg-white rounded-lg shadow-lg p-6">
//       <h3 className="text-2xl font-semibold text-gray-800 mb-6">Dealer List</h3>

//       {/* Search Section */}
//       <div className="mb-4 flex gap-2">
//         <input
//           type="text"
//           placeholder="Search by Name / mobile"
//           className="border px-3 py-1 rounded-lg"
//           value={searchFirstName}
//           onChange={(e) => setSearchFirstName(e.target.value)}
//         />
//         <button
//           onClick={handleSearch}
//           className="px-3 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
//         >
//           Search
//         </button>
//       </div>

//       {/* Sellers Table */}
//       <div className="overflow-x-auto">
//         <table className="min-w-full table-auto">
//           <thead>
//             <tr className="bg-gray-100">
//               <th className="py-2 px-4 text-sm font-medium text-gray-600">First Name</th>
//               <th className="py-2 px-4 text-sm font-medium text-gray-600">Last Name</th>
//               <th className="py-2 px-4 text-sm font-medium text-gray-600">Mobile</th>
//               <th className="py-2 px-4 text-sm font-medium text-gray-600">Wallet</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data.map((seller) => (
//               <tr key={seller.id} className="border-b hover:bg-gray-50">
//                 <td className="py-2 px-4 text-sm">{seller.firstName}</td>
//                 <td className="py-2 px-4 text-sm">{seller.lastName}</td>
//                 <td className="py-2 px-4 text-sm">{seller.mobile}</td>
//                 <td className="py-2 px-4 text-sm">{seller.walletAmount || 0}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {/* Pagination */}
//       <div className="mt-6 flex justify-center gap-2">
//         {[...Array(totalPages)].map((_, index) => (
//           <button
//             key={index}
//             onClick={() => handlePagination(index + 1)}
//             className={`px-3 py-1 rounded-lg hover:bg-blue-500 ${
//               currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-gray-200"
//             }`}
//           >
//             {index + 1}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default SellersList;








import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../utils/axios";

const SellersList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sellersPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");

  // Fetch sellers based on pagination and search
  const fetchSellers = async (page = 1, searchValue = "") => {
    try {
      const response = await axiosInstance.get("/all/sellers", {
        params: {
          page :currentPage,
          size: sellersPerPage,
          search: searchValue,
        },
      });

      setData(response?.data?.data || []);
      setTotalPages(response?.data?.pagination?.totalPages || 1);
    } catch (error) {
      console.log("Error fetching sellers", error);
    }
  };

  useEffect(() => {
    fetchSellers();
  }, [currentPage, searchQuery]); // Refetch when currentPage or search changes

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = () => {
    setCurrentPage(1);
    fetchSellers(1, searchQuery);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };


  const AddMoneyToWallet = async (item) => {
        try {
          const response = await axiosInstance.post('/transaction', {
            name: item.firstName,
            mobile: item.mobile,
            userId: item.id,
          });
          console.log('response is here', response);
          window.location.href = response.data.redirectUrl; // Redirect to payment gateway
        } catch (error) {
          console.log('error while processing payment', error);
        }
      };

  const renderPageNumbers = () => {
    let startPage = Math.max(1, currentPage - 2); // Start at currentPage - 2 or 1
    let endPage = Math.min(totalPages, currentPage + 2); // End at currentPage + 2 or totalPages

    // Adjust sliding window when at the edges of pagination
    if (currentPage <= 3) {
      endPage = Math.min(totalPages, 5);
    }
    if (currentPage >= totalPages - 2) {
      startPage = Math.max(1, totalPages - 4);
    }

    const pages = [];
    for (let i = startPage; i < endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-2">
      <h3 className="text-2xl font-semibold text-gray-800 mb-6">Dealer List</h3>

      {/* Search Section */}
      <div className="mb-4 flex gap-2">
        <input
          type="text"
          placeholder="Search by Name / Mobile"
          className="border px-3 py-1 rounded-lg"
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearchSubmit();
            }
          }}
        />
        <button
          onClick={handleSearchSubmit}
          className="px-3 py-1 bg-[#e2f2b2] hover:text-white rounded-lg hover:bg-[#4ED31A] transition"
        >
          Search
        </button>
      </div>

      {/* Sellers Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-2 px-4 text-sm font-medium text-gray-600">First Name</th>
              <th className="py-2 px-4 text-sm font-medium text-gray-600">Last Name</th>
              <th className="py-2 px-4 text-sm font-medium text-gray-600">Mobile</th>
              <th className="py-2 px-4 text-sm font-medium text-gray-600">Wallet</th>
              <th className="py-2 px-4 text-sm font-medium text-gray-600">Pay</th>
            </tr>
          </thead>
          <tbody>
            {data.map((seller) => (
              <tr key={seller.id} className="border-b hover:bg-gray-50">
                <td className="py-2 px-4 text-sm">{seller.firstName}</td>
                <td className="py-2 px-4 text-sm">{seller.lastName}</td>
                <td className="py-2 px-4 text-sm">{seller.mobile}</td>
                <td className="py-2 px-4 text-sm">{seller.Wallet.amount || 0}</td>
                <td className="py-3 px-4 text-sm">
                   {seller.Wallet.amount < 1 ? (
                     <button
                       onClick={() => AddMoneyToWallet(seller)}
                       className="px-4 py-2 bg-[#e2f2b2] rounded-lg flex items-center gap-2 hover:bg-[#22a722] hover:text-white transition-all duration-300 ease-in-out"
                     >
                       <span>Payment</span>
                     </button>
                   ) : (
                    <button
                    // onClick={() => AddMoneyToWallet(seller)}
                    disabled="true"
                    className="px-4 py-2 bg-[#e2f2b2] rounded-lg flex items-center gap-2 transition-all duration-300 ease-in-out"
                  >
                    <span>Completed</span>
                  </button>
                   )}
                 </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="mt-6 flex justify-center gap-2 items-center">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className={`px-3 py-1 bg-[#e2f2b2] hover:text-white rounded-lg hover:bg-[#4ED31A] ${
            currentPage === 1 ? "opacity-50" : ""
          }`}
        >
          Previous
        </button>

        {renderPageNumbers().map((page) => (
          <button
            key={page}
            onClick={() => handlePagination(page)}
            className={`px-3 py-1 rounded-lg ${
              currentPage === page ? "bg-[#4ED31A] text-white" : "bg-[#e2f2b2]"
            } hover:bg-blue-400 transition duration-200`}
          >
            {page}
          </button>
        ))}

        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className={`px-3 py-1 bg-[#e2f2b2] hover:text-white rounded-lg hover:bg-[#4ED31A] ${
            currentPage === totalPages ? "opacity-50" : ""
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SellersList;
