

// export default AddSellerModal;
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import RegistrationForm from "../../pages/Registration";

const AddSellerModal = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div>
      {/* Button with Tailwind CSS only */}
      <button
        className="bg-[#e2f2b2] hover:bg-[#3c771a] text-gray-700 hover:text-stone-50 font-semibold py-2 px-4 border-none rounded-md transition duration-200 ease-in-out"
        onClick={handleShow}
      >
        Add Dealer
      </button>

      {/* React-Bootstrap Modal */}
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <RegistrationForm />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddSellerModal;
