

import React, { useState } from 'react';
import { axiosInstance } from '../utils/axios';

const StoreProduct = () => {
  const [productName, setProductName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');  // New category state
  const [price, setPrice] = useState('');        // New price state
  const [mrp, setMrp] = useState('');            // New MRP state
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // For image preview
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  // Handle image file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);

    // Create a preview of the image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result); // Set the image preview URL
    };
    if (file) {
      reader.readAsDataURL(file); // Read the file to create the preview
    }
  };

  // Handle image removal
  const handleImageRemove = () => {
    setImageFile(null);
    setImagePreview(null); // Clear the preview
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!productName || !quantity || !description || !category || !price || !mrp || !imageFile) {
      setMessage('All fields are required');
      setMessageType('error');
      return;
    }

    setIsSubmitting(true);

    // Create a FormData object to send the form data, including the image file
    const formData = new FormData();
    formData.append('productName', productName);
    formData.append('quantity', quantity);
    formData.append('description', description);
    formData.append('category', category);  // Include category
    formData.append('price', price);        // Include price
    formData.append('mrp', mrp);            // Include MRP
    formData.append('image', imageFile);

    try {
      const response = await axiosInstance.post('/products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setMessage('Product created successfully!');
        setMessageType('success');
        setProductName('');
        setQuantity('');
        setDescription('');
        setCategory('');
        setPrice('');
        setMrp('');
        setImageFile(null);
        setImagePreview(null); // Clear image preview after submission
      }
    } catch (error) {
      setMessage('Failed to create product');
      setMessageType('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto bg-white p-8 shadow-md rounded-lg">
      <h2 className="text-2xl font-bold text-center mb-6">Store Product</h2>

      {/* Success/Error Message */}
      {message && (
        <div
          className={`p-4 mb-4 text-white ${messageType === 'success' ? 'bg-green-500' : 'bg-red-500'}`}
        >
          {message}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Product Name */}
        <div>
          <label htmlFor="productName" className="block text-sm font-medium text-gray-700">
            Product Name
          </label>
          <input
            type="text"
            id="productName"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
            placeholder="Enter product name"
            required
          />
        </div>

        {/* Quantity */}
        <div>
          <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
            Quantity
          </label>
          <input
            type="number"
            id="quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
            placeholder="Enter quantity"
            required
          />
        </div>

        {/* Description */}
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
            placeholder="Enter product description"
            rows="4"
            required
          />
        </div>

        {/* Category */}
        <div>
          <label htmlFor="category" className="block text-sm font-medium text-gray-700">
            Category
          </label>
          <input
            type="text"
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
            placeholder="Enter product category"
            required
          />
        </div>

        {/* Price */}
        <div>
          <label htmlFor="price" className="block text-sm font-medium text-gray-700">
            Price
          </label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
            placeholder="Enter product price"
            required
          />
        </div>

        {/* MRP */}
        <div>
          <label htmlFor="mrp" className="block text-sm font-medium text-gray-700">
            MRP
          </label>
          <input
            type="number"
            id="mrp"
            value={mrp}
            onChange={(e) => setMrp(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
            placeholder="Enter product MRP"
            required
          />
        </div>

        {/* Image Upload */}
        <div>
          <label htmlFor="image" className="block text-sm font-medium text-gray-700">
            Image
          </label>
          <input
            type="file"
            id="image"
            onChange={handleImageChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
            accept="image/*"
            required
          />
        </div>

        {/* Image Preview */}
        {imagePreview && (
          <div className="mt-4">
            <img
              src={imagePreview}
              alt="Image preview"
              className="w-full h-64 object-cover rounded-md"
            />
            <button
              type="button"
              onClick={handleImageRemove}
              className="mt-2 text-red-500 hover:text-red-700"
            >
              Remove Image
            </button>
          </div>
        )}

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full py-2 mt-4 text-white font-bold rounded-md ${
              isSubmitting ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'
            }`}
          >
            {isSubmitting ? 'Submitting...' : 'Store Product'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default StoreProduct;




// import React, { useState } from 'react';
// import { axiosInstance } from '../utils/axios';

// const StoreProduct = () => {
//   const [productName, setProductName] = useState('');
//   const [quantity, setQuantity] = useState('');
//   const [description, setDescription] = useState('');
//   const [category, setCategory] = useState('');  // New category state
//   const [price, setPrice] = useState('');        // New price state
//   const [mrp, setMrp] = useState('');            // New MRP state
//   const [imageFile, setImageFile] = useState(null);
//   const [imagePreview, setImagePreview] = useState(null); // For image preview
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [message, setMessage] = useState('');
//   const [messageType, setMessageType] = useState(''); // 'success' or 'error'

//   // New state to handle whether we are adding an offer product or not
//   const [isOfferProduct, setIsOfferProduct] = useState(false);
//   const [offerDiscount, setOfferDiscount] = useState(''); // Discount for the offer

//   // Handle image file change
//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     setImageFile(file);

//     // Create a preview of the image
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       setImagePreview(reader.result); // Set the image preview URL
//     };
//     if (file) {
//       reader.readAsDataURL(file); // Read the file to create the preview
//     }
//   };

//   // Handle image removal
//   const handleImageRemove = () => {
//     setImageFile(null);
//     setImagePreview(null); // Clear the preview
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!productName || !quantity || !description || !category || !price || !mrp || !imageFile) {
//       setMessage('All fields are required');
//       setMessageType('error');
//       return;
//     }

//     setIsSubmitting(true);

//     // Create a FormData object to send the form data, including the image file
//     const formData = new FormData();
//     formData.append('productName', productName);
//     formData.append('quantity', quantity);
//     formData.append('description', description);
//     formData.append('category', category);  // Include category
//     formData.append('price', price);        // Include price
//     formData.append('mrp', mrp);            // Include MRP
//     formData.append('image', imageFile);

//     // If it's an offer product, append the offer discount to the formData
//     if (isOfferProduct) {
//       formData.append('offerDiscount', offerDiscount);
//     }

//     try {
//       const response = await axiosInstance.post('/products', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       if (response.status === 201) {
//         setMessage('Product created successfully!');
//         setMessageType('success');
//         setProductName('');
//         setQuantity('');
//         setDescription('');
//         setCategory('');
//         setPrice('');
//         setMrp('');
//         setImageFile(null);
//         setImagePreview(null); // Clear image preview after submission
//         setOfferDiscount('');  // Clear offer discount if any
//         setIsOfferProduct(false); // Reset to regular product type
//       }
//     } catch (error) {
//       setMessage('Failed to create product');
//       setMessageType('error');
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   return (
//     <div className="max-w-lg mx-auto bg-white p-8 shadow-md rounded-lg">
//       <h2 className="text-2xl font-bold text-center mb-6">Store Product</h2>

//       {/* Success/Error Message */}
//       {message && (
//         <div
//           className={`p-4 mb-4 text-white ${messageType === 'success' ? 'bg-green-500' : 'bg-red-500'}`}
//         >
//           {message}
//         </div>
//       )}

//       <form onSubmit={handleSubmit} className="space-y-4">
//         {/* Product Name */}
//         <div>
//           <label htmlFor="productName" className="block text-sm font-medium text-gray-700">
//             Product Name
//           </label>
//           <input
//             type="text"
//             id="productName"
//             value={productName}
//             onChange={(e) => setProductName(e.target.value)}
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
//             placeholder="Enter product name"
//             required
//           />
//         </div>

//         {/* Quantity */}
//         <div>
//           <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
//             Quantity
//           </label>
//           <input
//             type="number"
//             id="quantity"
//             value={quantity}
//             onChange={(e) => setQuantity(e.target.value)}
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
//             placeholder="Enter quantity"
//             required
//           />
//         </div>

//         {/* Description */}
//         <div>
//           <label htmlFor="description" className="block text-sm font-medium text-gray-700">
//             Description
//           </label>
//           <textarea
//             id="description"
//             value={description}
//             onChange={(e) => setDescription(e.target.value)}
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
//             placeholder="Enter product description"
//             rows="4"
//             required
//           />
//         </div>

//         {/* Category */}
//         <div>
//           <label htmlFor="category" className="block text-sm font-medium text-gray-700">
//             Category
//           </label>
//           <input
//             type="text"
//             id="category"
//             value={category}
//             onChange={(e) => setCategory(e.target.value)}
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
//             placeholder="Enter product category"
//             required
//           />
//         </div>

//         {/* Price */}
//         <div>
//           <label htmlFor="price" className="block text-sm font-medium text-gray-700">
//             Price
//           </label>
//           <input
//             type="number"
//             id="price"
//             value={price}
//             onChange={(e) => setPrice(e.target.value)}
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
//             placeholder="Enter product price"
//             required
//           />
//         </div>

//         {/* MRP */}
//         <div>
//           <label htmlFor="mrp" className="block text-sm font-medium text-gray-700">
//             MRP
//           </label>
//           <input
//             type="number"
//             id="mrp"
//             value={mrp}
//             onChange={(e) => setMrp(e.target.value)}
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
//             placeholder="Enter product MRP"
//             required
//           />
//         </div>

//         {/* Image Upload */}
//         <div>
//           <label htmlFor="image" className="block text-sm font-medium text-gray-700">
//             Image
//           </label>
//           <input
//             type="file"
//             id="image"
//             onChange={handleImageChange}
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
//             accept="image/*"
//             required
//           />
//         </div>

//         {/* Image Preview */}
//         {imagePreview && (
//           <div className="mt-4">
//             <img
//               src={imagePreview}
//               alt="Image preview"
//               className="w-full h-64 object-cover rounded-md"
//             />
//             <button
//               type="button"
//               onClick={handleImageRemove}
//               className="mt-2 text-red-500 hover:text-red-700"
//             >
//               Remove Image
//             </button>
//           </div>
//         )}

//         {/* Offer Product Toggle */}
//         <div>
//           <button
//             type="button"
//             onClick={() => setIsOfferProduct((prev) => !prev)}
//             className="mt-2 p-2 w-full text-white bg-indigo-600 rounded-md hover:bg-indigo-700"
//           >
//             {isOfferProduct ? 'Cancel Offer' : 'Add Offer Product'}
//           </button>
//         </div>

//         {/* Offer Discount Field */}
//         {isOfferProduct && (
//           <div>
//             <label htmlFor="offerDiscount" className="block text-sm font-medium text-gray-700">
//               Offer Discount
//             </label>
//             <input
//               type="number"
//               id="offerDiscount"
//               value={offerDiscount}
//               onChange={(e) => setOfferDiscount(e.target.value)}
//               className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
//               placeholder="Enter discount percentage"
//             />
//           </div>
//         )}

//         {/* Submit Button */}
//         <div>
//           <button
//             type="submit"
//             disabled={isSubmitting}
//             className={`w-full py-2 mt-4 text-white font-bold rounded-md ${
//               isSubmitting ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'
//             }`}
//           >
//             {isSubmitting ? 'Submitting...' : 'Store Product'}
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default StoreProduct;
