



// import React, { useState, useRef, useEffect } from "react";
// import { Navbar, Form, FormControl, Button } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import { FiShoppingCart } from "react-icons/fi"; // Importing the cart icon
// import { useSelector, useDispatch } from "react-redux"; // To access Redux state and dispatch actions
// import { FaHome } from 'react-icons/fa'; // Import the home icon

// import { removeToken } from "../../app/features/login/authSlice";
// import "./header.css";
// import { removeBuyer } from "../../app/features/login/buyerSlice";

// const Header = () => {

//   const buyer = useSelector((state) => state.buyer.buyer); // Access the buyer data from Redux
//   console.log('buyer in header',buyer);

//   const [dropdownOpen, setDropdownOpen] = useState(false); // State to toggle dropdown
//   const navigate = useNavigate(); // For redirection on search
//   const [locationPermission, setLocationPermission] = useState(false); // State to store location permission status

//   const dropdownRef = useRef(null); // Create a reference for the dropdown menu

//   // Access token from Redux state
//   const token = useSelector((state) => state.auth.token);  // Assuming the token is in auth slice
//   const dispatch = useDispatch(); // To dispatch actions

//   // Toggle dropdown visibility
//   const toggleMenuDropdown = (e) => {
//     e.stopPropagation(); // Prevent the click event from propagating to the document
//     setDropdownOpen(!dropdownOpen);
//   };

//   // Request location access from user
//   const requestLocation = () => {
//     return new Promise((resolve, reject) => {
//       if (navigator.geolocation) {
//         navigator.geolocation.getCurrentPosition(
//           (position) => {
//             resolve(position);
//           },
//           (error) => {
//             reject(error);
//           },
//           {
//           enableHighAccuracy: true,
//           timeout: 10000,  // Set a timeout to avoid waiting forever
//           maximumAge: 0  
//           },
//         );
//       } else {
//         reject("Geolocation is not supported by this browser.");
//       }
//     });
//   };

//   // Handle search submission
//   const handleSearch = async (e) => {
//     e.preventDefault();
//     const searchQuery = e.target.search.value;

//     try {
//       // Request user location before performing search
//       const position = await requestLocation();

//       // If location is granted, extract latitude and longitude
//       const latitude = position.coords.latitude;
//       const longitude = position.coords.longitude;

//       // After getting location, proceed with search
//       navigate(`/?query=${searchQuery}&latitude=${latitude}&longitude=${longitude}`);
//     } catch (error) {
//       console.error("Location error:", error);
//       // If location access is denied or failed, inform the user and still proceed with search
//       setLocationPermission(false);
//       // alert("Location access denied or unavailable. You can still search without location.");
//       navigate(`/?query=${searchQuery}`);
//     }
//   };

//   // Close the dropdown if clicked outside
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setDropdownOpen(false); // Close dropdown if clicked outside
//       }
//     };

//     // Add event listener to detect clicks outside the dropdown
//     document.addEventListener("click", handleClickOutside);

//     // Clean up the event listener when the component unmounts
//     return () => {
//       document.removeEventListener("click", handleClickOutside);
//     };
//   }, []);

//   // Close dropdown on link click and prevent default form submission behavior
//   const handleLinkClick = (e) => {
//     setDropdownOpen(false); // Close the dropdown
//     navigate(e);
//   };

//   // Handle logout action
//   const handleLogout = () => {
//     // Remove token from localStorage and Redux store
//     localStorage.removeItem('buyer');
//     localStorage.removeItem("token"); // Clear token from localStorage
//     dispatch(removeToken()); // Dispatch logout action to clear token from Redux
//     dispatch(removeBuyer());
//     // Redirect to login page
//     navigate("/login");
//   };

//   return (    
// <Navbar bg="light" expand="lg" className="header-navbar fixed-top"
// >
//   <div className="container">
//     <div className="header-top d-flex align-items-center justify-content-between">
//       {/* Menu Icon */}
//       <button className="menu-icon" 
//       onClick={toggleMenuDropdown}
//       onMouseEnter={() => setDropdownOpen(true)} // Open on hover
//       >
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           viewBox="0 0 24 24"
//           fill="currentColor"
//           width="24px"
//           height="24px"
//         >
//           <path d="M3 6h18M3 12h18M3 18h18" stroke="currentColor" strokeWidth="2" />
//         </svg>
//       </button>

//       {/* Logo */}
//       <Navbar.Brand as={Link} to="/" className="ml-5">
//         <img src="/logo.jpeg" alt="Logo" className="header-logo" />
//       </Navbar.Brand>
//     </div>

//     {/* Dropdown (appears below Menu button) */}
//     {dropdownOpen && (
//       <div className="menu-dropdown" 
//       ref={dropdownRef}
//       onMouseEnter={() => setDropdownOpen(true)}
//       onMouseLeave={() => setDropdownOpen(false)}
      
//       >
        
//         <div className="menu-dropdown-header">
//         <Button
//         href="/"
//         type="submit"
//         style={{ backgroundColor: '#e2f2b2', color: 'black',borderColor:'#e2f2b2' }} // Set background to black and text/icon color to white
//       >
//         <FaHome size={20} />
//       </Button>
//                 <div>  Ratefry </div> </div>
//         <ul className="menu-dropdown-list">
         
//           {/* <li onClick={() => handleLinkClick("/login")}>Dealer Login</li> */}
//           {!token &&(
//             <>
//           <li onClick={() => handleLinkClick("/user/login")}>User Login</li>
//           <li onClick={() => handleLinkClick("/admin/login")}>Admin Login</li>
//           </>
//           )}

//           {buyer === 'admin' && (
//             <>
//               <li onClick={() => handleLinkClick("/admin/dashboard")}>
//                 <button type="button">Admin Dashboard</button>
//               </li>
//             </>
//           )}


//           {
//             buyer === 'buyer' && (
//               <li onClick={() => handleLinkClick("/user/profile")}>
//               <button type="button">user profile</button>
//             </li>
//             )
//           }

//           {/* Logout Button (only visible if the user is logged in) */}
//           {token && (
//             <li onClick={handleLogout}>
//               <button type="button">Logout</button>
//             </li>
//           )}
//         </ul>
//       </div>
//     )}

//     {/* Search Bar */}
//     <div>
//       <Form className="d-flex" onSubmit={handleSearch}>
//         <FormControl
//           type="search"
//           name="search"
//           placeholder="Search for Products, Brands, and More"
//           className="me-2"
//           aria-label="Search"
//         />
//         <Button
//           variant="primary"
//           type="submit"
//           style={{ backgroundColor: '#e2f2b2', color: 'black',borderColor:'#e2f2b2' }}
//         >
//           Search
//         </Button>
//       </Form>
//     </div>
//   </div>
// </Navbar>

//   );
// };

// export default Header;








import React, { useState, useRef, useEffect } from "react";
import { Navbar, Form, FormControl, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { FaHome } from "react-icons/fa";

import { removeToken } from "../../app/features/login/authSlice";
import { removeBuyer } from "../../app/features/login/buyerSlice";
import "./header.css";
import LocationPermissionModal from "./locationPermissionModal"; // Import modal

const Header = () => {
  const buyer = useSelector((state) => state.buyer.buyer);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [locationPermissionModalOpen, setLocationPermissionModalOpen] = useState(false);

  const[search,setSearch] = useState('');

  const dropdownRef = useRef(null);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const toggleMenuDropdown = (e) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const requestLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position),
          (error) => reject(error),
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          }
        );
      } else {
        reject("Geolocation is not supported by this browser.");
      }
    });
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    const searchQuery = e.target.search.value;
  setSearch(searchQuery);
    try {
      const position = await requestLocation();
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      navigate(`/?query=${searchQuery}&latitude=${latitude}&longitude=${longitude}`);
    } catch (error) {
      console.error("Location error:", error);
      navigate(`/?query=${searchQuery}`);
      setLocationPermissionModalOpen(true);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("buyer");
    localStorage.removeItem("token");
    dispatch(removeToken());
    dispatch(removeBuyer());
    navigate("/login");
  };

  const handleModalClose = () => {
    setLocationPermissionModalOpen(false);
  };

  const handleRetry = async () => {
    try {
      // alert('working')
      const position = await requestLocation();
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      navigate(`/?query=${search}&latitude=${latitude}&longitude=${longitude}`);
      setLocationPermissionModalOpen(false);
    } catch (error) {
      console.error("Retry failed - location still unavailable.");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Navbar bg="light" expand="lg" className="header-navbar fixed-top">
        <div className="container">
          <div className="header-top d-flex align-items-center justify-content-between">
            {/* Menu Icon */}
            <button className="menu-icon" 
            onClick={toggleMenuDropdown}
            onMouseEnter={() => setDropdownOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="24px"
                height="24px"
              >
                <path d="M3 6h18M3 12h18M3 18h18" stroke="currentColor" strokeWidth="2" />
              </svg>
            </button>

            {/* Logo */}
            <Navbar.Brand as={Link} to="/" className="ml-5 mb-2">
              <img src="/logo.png" alt="Logo" className="header-logo" />
            </Navbar.Brand>
          </div>

          {/* Dropdown Menu */}
          {dropdownOpen && (
            <div className="menu-dropdown" 
             ref={dropdownRef}
             onMouseEnter={() => setDropdownOpen(true)}
             onMouseLeave={() => setDropdownOpen(false)}
             >
              <div className="menu-dropdown-header">
                <Button
                  href="/"
                  type="submit"
                  style={{ backgroundColor: '#e2f2b2', color: 'black', borderColor: '#e2f2b2' }}
                >
                  <FaHome size={20} />
                </Button>
                <div>Ratefry</div>
              </div>
              <ul className="menu-dropdown-list">
                {!token && (
                  <>
                    <li onClick={() => navigate("/user/login")}>User Login</li>
                    <li onClick={() => navigate("/admin/login")}>Admin Login</li>
                  </>
                )}

                {buyer === "admin" && (
                  <li onClick={() => navigate("/admin/dashboard")}>
                    <button type="button">Admin Dashboard</button>
                  </li>
                )}

                {buyer === "buyer" && (
                  <li onClick={() => navigate("/user/profile")}>
                    <button type="button">User Profile</button>
                  </li>
                )}

                {token && (
                  <li onClick={handleLogout}>
                    <button type="button">Logout</button>
                  </li>
                )}
              </ul>
            </div>
          )}

          {/* Search Bar */}
          <div>
            <Form className="d-flex" onSubmit={handleSearch}>
              <FormControl
                type="search"
                name="search"
                placeholder="Search for Products, Brands, and More"
                className="me-2"
                aria-label="Search"
              />
              <Button
                variant="primary"
                type="submit"
                style={{ backgroundColor: '#e2f2b2', color: 'black', borderColor: '#e2f2b2' }}
              >
                Search
              </Button>
            </Form>
          </div>
        </div>
      </Navbar>

      {/* Modal */}
      <LocationPermissionModal
        show={locationPermissionModalOpen}
        handleClose={handleModalClose}
        handleRetry={handleRetry}
      />
    </>
  );
};

export default Header;