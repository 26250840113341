

import React, { useState, useEffect } from "react";
import { axiosInstance } from "../utils/axios";
import { toast } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'; // Import the icons
import { useNavigate } from "react-router-dom";
import SelectDealerCategories from '../components/AdminDashboardComponent/SelectDealerCategories';
import axios from "axios";

import SuccessModal from '../components/SuccessModal';


const RegistrationForm = () => {

  const [message,setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalToggle = () => {
    setIsModalOpen(!isModalOpen);
    if(isModalOpen){
      window.location.reload();
    }
  };


  const [selectedCategories, setSelectedCategories] = useState([]);

  console.log('selected categories in registration form ',selectedCategories);


  const navigate = useNavigate(); //
  const[allowLocation,setAllowLocation] =useState('');

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password:"",
    shopName:"",
    shopAddress:"",
    gst: "",
    photo: null,
    categoryIds : '',
    city:'',
    state:'',
    area:'',
    postalCode:'',
    country:'',
    bankDetails: {
      bankName: "",
      accountNumber: "",
      accountHolderName: "",
      branch: "",
    },
    kyc: null,
    gstCertificate: null,
    location: { latitude: null, longitude: null }, // Store geolocation
  });

  const [filePreviews, setFilePreviews] = useState({
    photo: null,
    kyc: null,
    gstCertificate: null,
  });

  const [isLoading, setIsLoading] = useState(false); // State to track form submission

  // Function to get current geolocation
  // useEffect(() => {
  //   locationAllowFunction();
  // }, []); // Empty dependency array ensures this runs only once on component mount



  const locationAllowFunction = async (value) => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Successfully got the position
            setFormData((prevData) => ({
              ...prevData,
              location: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
            }));
  
            console.log('Position:', position.coords.latitude, position.coords.longitude);
            setIsLoading(false)
            // Resolve with latitude and longitude
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            setIsLoading(false)
            console.error("Error fetching geolocation:", error);
  
            if (error.code === 1) {
             
              // User denied location access
              alert("Location access denied. Please enable location services.");
            } else if (error.code === 2) {
              // Location unavailable (might be because it's turned off or device issue)
              alert("Location is unavailable. Please turn on location services.");
            } else {
              // Handle other errors
              alert("An error occurred while fetching your location.");
            }
  
            reject(error); // Reject the promise with the error
          }
        );
      } else {
        setIsLoading(false)
        console.log("Geolocation is not supported by this browser.");
        alert("Geolocation is not supported by your browser.");
        reject("Geolocation is not supported");
      }
    });
  };
  


  const getlocationData =async()=>{
    const { latitude, longitude } = await locationAllowFunction();
    if(latitude&&longitude){
      console.log(latitude,longitude)
        // Fetch location details based on the latitude and longitude
        axios
          .get(
            `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=c45d8ebb7d6e434ba91531b92d08a14c&language=en&pretty=1`
          )
          .then((response) => {
            const data = response.data;
            if (data.results && data.results.length > 0) {
              const result = data.results[0];
              setFormData({
                shopAddress: result.formatted,
                city: result.components.city || "City not found",
                country: result.components.country || "Country not found",
                state: result.components.state || "State not found",
                postalCode: result.components.postcode || "Postcode not found",
              });
            } else {
              alert("Location not found");
            }
          })
          .catch((error) => {
            console.log("Error fetching location:", error);
            alert("Error fetching location");
          })
          .finally(() => {
            // setLoading(false);
          });
    
}
  }


  useEffect(()=>{
    getlocationData();
  },[])
  

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0], // For file input, store the file object
      }));

      // Generate preview based on file type
      if (files[0]) {
        const file = files[0];
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setFilePreviews((prevPreviews) => ({
              ...prevPreviews,
              [name]: reader.result, // Set the image preview
            }));
          };
          reader.readAsDataURL(file);
        } else if (file.type === "application/pdf") {
          setFilePreviews((prevPreviews) => ({
            ...prevPreviews,
            [name]: "PDF Document Selected", // Display PDF message
          }));
        }
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle remove file action
  const handleRemoveFile = (name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: null, // Remove the file
    }));
    setFilePreviews((prevPreviews) => ({
      ...prevPreviews,
      [name]: null, // Clear the preview
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setIsLoading(true);

     //
    //   
    //   
      // locationAllowFunction('submit');
      const { latitude, longitude } = await locationAllowFunction();
      console.log('ati longi',latitude,longitude)
      if(!latitude && !longitude){
      alert('allow location is manadory ')
      setIsLoading(false)
      return
    }
    // Ensure latitude and longitude are valid numbers
    const latitude1 = parseFloat(latitude);
    const longitude1 = parseFloat(longitude);

    console.log('latitude lngitde',latitude,longitude)
  
    // if (isNaN(latitude) || isNaN(longitude)) {
    //   alert('Invalid location coordinates.');
    //   return; // Prevent form submission if coordinates are invalid
    // }
  
    // Prepare FormData to send
    const formDataToSend = new FormData();
  
    for (let key in formData) {
      if (formData[key] && typeof formData[key] !== 'object') {
        formDataToSend.append(key, formData[key]);
      } else if (formData[key]) {
        // For nested objects like bankDetails, we loop through them too
        for (let subKey in formData[key]) {
          formDataToSend.append(`${key}[${subKey}]`, formData[key][subKey]);
        }
      }
    }
  
    // Manually add the location (with numeric values)
    formDataToSend.append('latitude', latitude1);
    formDataToSend.append('longitude', longitude1);

    //bank details append here
    // formDataToSend.append('bankName', formData.bankDetails.bankName);
    // formDataToSend.append('accountNumber', formData.bankDetails.accountNumber);
    // formDataToSend.append('accountHolderName', formData.bankDetails.accountHolderName);
    // formDataToSend.append('branch', formData.bankDetails.branch);
 
    // Append files specifically
    if (formData.photo) formDataToSend.append('photo', formData.photo);
    if (formData.kyc) formDataToSend.append('kyc', formData.kyc);
    if (formData.gstCertificate) formDataToSend.append('gstCertificate', formData.gstCertificate);
  
    // formDataToSend.append('categoryIds',selectedCategories)
    formDataToSend.append('categoryIds', selectedCategories.join(','));


    try {
      const response = await axiosInstance.post('/register', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      if (response.data.status) {
        setMessage(response.data.message);
        modalToggle();
        // navigate('/login')
        // toast.success(response.data.message);
      } else {
        toast.success('Registration failed. Please try again.');
      }
      console.log('Response:', response.data);
    }  catch (error) {
      console.error('Error submitting form:', error);
  
      // Check if error response has a message
      if (error.response && error.response.data && error.response.data.message) {
        // Show the message from backend error response
        setAllowLocation(error?.response?.data?.location);
        alert(error.response.data.message);
      } else {
        // Default error message
        alert('An unexpected error occurred. Please try again.');
      }
    }finally {
      setIsLoading(false); // Set loading to false after the submission is complete
    }
  };
  
  const [showPassword, setShowPassword] = useState(false);


   // Toggle password visibility
   const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  

  return (
    <div className="container mx-auto my-8 p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-6 text-center">Dealer Registration Form</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* First Name and Last Name */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700">First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
        </div>

        {/* Mobile and Email */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700">Mobile</label>
            <input
              type="number"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
       

          <div>
      <label className="block text-gray-700">Password</label>
      <div className="relative">
        <input
          type={showPassword ? 'text' : 'password'}
          name="password"
          value={formData.password}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-md"
          required
        />
        <span
          onClick={togglePassword}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
        >
          {showPassword ? (
            <AiOutlineEyeInvisible size={20} />
          ) : (
            <AiOutlineEye size={20} />
          )}
        </span>
      </div>
    </div>

          <div>
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">ShopName</label>
            <input
              type="shopName"
              name="shopName"
              value={formData.shopName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">ShopAddress</label>
            <input
              type="shopAddress"
              name="shopAddress"
              value={formData.shopAddress}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700">City</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">State</label>
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700">Country</label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div>
          <label className="block text-gray-700">Area/Colony</label>
          <input
            type="text"
            name="area"
            value={formData.area}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

      

        <div>
          <label className="block text-gray-700">GST Number</label>
          <input
            type="text"
            name="gst"
            value={formData.gst}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        </div>

        <SelectDealerCategories onSelectionChange={setSelectedCategories} />
        
        {/* GST */}
       
        {/* Photo and KYC */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700">KYC Document AadharCard (Upload Image)</label>
            <input
              type="file"
              name="photo"
              onChange={handleChange}
              accept="image/*"
              className="w-full p-2 border border-gray-300 rounded-md"
            />
            {filePreviews.photo && (
              <div className="mt-2 relative">
                <img
                  src={filePreviews.photo}
                  alt="Photo Preview"
                  className="w-32 h-32 object-cover"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveFile("photo")}
                  className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                >
                  X
                </button>
              </div>
            )}
          </div>
          <div>
            <label className="block text-gray-700">KYC Document PanCard (PDF/Image)</label>
            <input
              type="file"
              name="kyc"
              onChange={handleChange}
              accept="application/pdf,image/*"
              className="w-full p-2 border border-gray-300 rounded-md"
            />
            {filePreviews.kyc && (
              <div className="mt-2 relative">
                {filePreviews.kyc.startsWith("PDF") ? (
                  <p>{filePreviews.kyc}</p>
                ) : (
                  <img
                    src={filePreviews.kyc}
                    alt="KYC Preview"
                    className="w-32 h-32 object-cover"
                  />
                )}
                <button
                  type="button"
                  onClick={() => handleRemoveFile("kyc")}
                  className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                >
                  X
                </button>
              </div>
            )}
          </div>
        </div>

        {/* GST Certificate */}
        <div>
          <label className="block text-gray-700">GST Certificate (Image/PDF)</label>
          <input
            type="file"
            name="gstCertificate"
            onChange={handleChange}
            accept="application/pdf,image/*"
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {filePreviews.gstCertificate && (
            <div className="mt-2 relative">
              {filePreviews.gstCertificate.startsWith("PDF") ? (
                <p>{filePreviews.gstCertificate}</p>
              ) : (
                <img
                  src={filePreviews.gstCertificate}
                  alt="GST Certificate Preview"
                  className="w-32 h-32 object-cover"
                />
              )}
              <button
                type="button"
                onClick={() => handleRemoveFile("gstCertificate")}
                className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
              >
                X
              </button>
            </div>
          )}
        </div>

        <div className="text-center">
        <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
            disabled={isLoading} // Disable the button while loading
          >
            {isLoading ? (
              <span className="spinner-border spinner-border-sm"></span> // Loader
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
      {isModalOpen && (
        <SuccessModal
          title="Congratulations"
          message={`${message} 'thank you for choosing us`}
          onClose={modalToggle}
        />
      )}

    
    </div>
  );
};
export default RegistrationForm;