import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { axiosInstance } from "../../utils/axios";
import { useNavigate } from "react-router-dom";


const UserRegisterModal = ({ showModal, onClose, onRegister }) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password,setPassword] = useState("");


  const navigate = useNavigate(); // For redirecting to login page

  const handleSubmit =async (e) => {
    e.preventDefault();
    if (!name || !mobile || !email) {
      toast.error("All fields are required!");
      return;
    }
    // Trigger register buyer
    const loginData = { 
       name,
      mobile,
      email,
      password,
    };
    // Send login request to the server
    const response = await axiosInstance.post('/buyers', loginData);
    
    console.log('respone is ehere',response)
    onRegister(name, mobile, email);
    // Reset form fields
    setName("");
    setMobile("");
    setEmail("");
    onClose(); // Close the modal after registration
  };


  const handleRedirectToLogin = () => {
    navigate("/user/login"); // Redirect to the login page
    onClose(); // Close the modal
  };

  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Register</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
            />
          </Form.Group>

          <Form.Group controlId="formMobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              type="text"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter your mobile"
            />
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="m-2">
            Register
          </Button>

          <Button variant="primary" className="bg-green-300" onClick={handleRedirectToLogin}>
              Go to Login
            </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UserRegisterModal;
