// redux/buyerSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  buyer: localStorage.getItem('buyer') || null, // Get buyer data from localStorage if available
};

const buyerSlice = createSlice({
  name: 'buyer',
  initialState,
  reducers: {
    setBuyer: (state, action) => {
      state.buyer = action.payload;
      localStorage.setItem('buyer',action.payload); // Save buyer data to localStorage
    },
    removeBuyer: (state) => {
      state.buyer = null;
      localStorage.removeItem('buyer'); // Remove buyer data from localStorage
    },
  },
});

export const { setBuyer, removeBuyer } = buyerSlice.actions;

export default buyerSlice.reducer;
