import { configureStore } from "@reduxjs/toolkit";
import cartSlice, { cartMiddleware } from "./features/cart/cartSlice";
import authReducer from './features/login/authSlice';
import buyerReducer from './features/login/buyerSlice'; // Import buyerSlice

export const store = configureStore({
  reducer: {
    cart: cartSlice,
    auth: authReducer,
    buyer: buyerReducer, // Add buyerSlice to the store
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(cartMiddleware),
});
