
import React, { useState, useEffect } from "react";
import { AiOutlineArrowLeft, AiOutlineCamera } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {getSingleBuyerDetails, updateSingleBuyerDetails } from "../utils/apiCall";
import SuccessModal from "../components/SuccessModal";
import { removeToken } from "../app/features/login/authSlice";
import { useDispatch } from "react-redux";
import { removeBuyer } from "../app/features/login/buyerSlice";

const UserProfile = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    mobile: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  // Fetch user details when the component mounts
  useEffect(() => {
    getDealerDetails();
  }, []);

  const getDealerDetails = async () => {
    try {
      const response = await getSingleBuyerDetails();
      console.log('response is here', response);
      if (response && response.data) {
        setFormData({
          id: response.data.id,
          name: response.data.name,
          mobile: response.data.mobile,
          email: response.data.email,
          password: '',
          confirmPassword: '',
        });
      }
    } catch (error) {
      console.error('Failed to fetch dealer details', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form state dynamically
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Check password match logic
    if (name === "confirmPassword" || name === "password") {
      setPasswordsMatch(formData.password === value || name === "confirmPassword" && value === '');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.password || !formData.confirmPassword) {
        alert("Please fill in both password fields.");
        return;
      }

      if (formData.password !== formData.confirmPassword) {
        alert("Passwords do not match.");
        return;
      }

      setLoading(true);
      const response = await updateSingleBuyerDetails({ password: formData.password });
      setMessage(response.message);
      //
      localStorage.removeItem('buyer');
      localStorage.removeItem("token"); // Clear token from localStorage
      dispatch(removeToken()); // Dispatch logout action to clear token from Redux
      dispatch(removeBuyer());
      // Redirect to login page
      navigate('/user/login')
      if (response.status) {
        modalToggle();
      }
    } catch (error) {
      console.error('Error while updating password', error);
    } finally {
      setLoading(false);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 mt-20">
      <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6">
        {/* Back Button */}
        <button
          className="text-gray-600 hover:text-gray-800"
          onClick={() => navigate("/")}
        >
          <AiOutlineArrowLeft size={24} />
        </button>

        {/* Fixed Profile Image */}
        <div className="flex flex-col items-center mb-6 relative">
          <div className="relative">
            <img
              src="https://via.placeholder.com/100"
              alt="User Profile"
              className="w-24 h-24 rounded-full object-cover border-2 border-gray-400"
            />
          </div>
        </div>

        {/* User Info Section */}
        {/* <div className="text-xl font-bold text-green-600 p-4 border border-gray-300 rounded-lg bg-gray-100 w-fit mx-auto mt-5 mb-5">
          Admin Id : {formData.id}
        </div> */}

        {/* Form for password change */}
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Display Read-only fields */}
          {["name", "mobile", "email"].map((field) => (
            <div key={field}>
              <label className="block text-gray-700">
                {field.replace(/([A-Z])/g, " $1")}
              </label>
              <input
                type="text"
                name={field}
                value={formData[field]}
                readOnly
                className="w-full px-4 py-2 border rounded-lg focus:outline-none bg-gray-100 cursor-not-allowed"
              />
            </div>
          ))}

          {/* Password Field */}
          <div>
            <label className="block text-gray-700">New Password</label>
            <input
              type="password"
              name="password"
              placeholder="Enter new password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
            />
          </div>

          {/* Confirm Password Field */}
          <div>
            <label className="block text-gray-700">Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              placeholder="Re-enter password"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
            />
            {/* Error message when passwords don't match */}
            {!passwordsMatch && formData.confirmPassword && (
              <p className="text-red-500 text-sm mt-1">Passwords do not match.</p>
            )}
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              className={`w-full py-2 px-4 rounded-lg transition ${
                passwordsMatch && formData.confirmPassword
                  ? 'bg-[#e2f2b2] hover:text-white hover:bg-[#4ED31A]'
                  : 'bg-[#e2f2b2] hover:text-white cursor-not-allowed'
              }`}
              disabled={!passwordsMatch || loading || !formData.confirmPassword}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>

      {/* Success Modal */}
      {isModalOpen && (
        <SuccessModal
          title="Success"
          message={message}
          onClose={modalToggle}
        />
      )}
    </div>
  );
};

export default UserProfile;
