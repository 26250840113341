// import React, { useState, useEffect } from "react";
// import { Modal, Button, Form } from "react-bootstrap";
// import { toast } from "react-toastify";
// import { axiosInstance } from "../../utils/axios";
// // import { axiosInstance } from "../utils/axios";  // Import your axios instance

// const EditProductModal = ({ show, handleClose, productData, setProductData }) => {
//   const [imagePreview, setImagePreview] = useState(null);

//   useEffect(() => {
//     if (productData?.image) {
//       setImagePreview(URL.createObjectURL(productData.image));
//     } else {
//       setImagePreview(null);
//     }
//   }, [productData?.image]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProductData((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setProductData((prev) => ({ ...prev, image: file }));
//     }
//   };

//   const handleRemoveImage = () => {
//     setProductData((prev) => ({ ...prev, image: null }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Validation
//     if (!productData.productName || !productData.quantity || !productData.description || !productData.category || !productData.price || !productData.mrp || !productData.image) {
//       alert('All fields are required');
//       return;
//     }

//     // Send updated data to the parent
//     handleUpdateProduct(productData);
//   };

//   const handleUpdateProduct = async (productData) => {
//         try {
//           await axiosInstance.put(`/products/${productData.id}`, productData);
//           toast.success("Product updated successfully!");
//         } catch (error) {
//           toast.error("Error updating product!");
//         }
//       };

//       console.log('proudct data',productData);

//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>Edit Product</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Form onSubmit={handleSubmit}>
//           <Form.Group controlId="formProductName">
//             <Form.Label>Product Name</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter product name"
//               name="productName"
//               value={productData.productName || ""}
//               onChange={handleChange}
//             />
//           </Form.Group>

//           <Form.Group controlId="formDescription">
//             <Form.Label>Description</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter product description"
//               name="description"
//               value={productData.description || ""}
//               onChange={handleChange}
//             />
//           </Form.Group>

//           <Form.Group controlId="formPrice">
//             <Form.Label>Price</Form.Label>
//             <Form.Control
//               type="number"
//               placeholder="Enter product price"
//               name="price"
//               value={productData.price || ""}
//               onChange={handleChange}
//             />
//           </Form.Group>

//           <Form.Group controlId="formQuantity">
//             <Form.Label>Quantity</Form.Label>
//             <Form.Control
//               type="number"
//               placeholder="Enter product quantity"
//               name="quantity"
//               value={productData.quantity || ""}
//               onChange={handleChange}
//             />
//           </Form.Group>

//           {/* Image upload section */}
//           <Form.Group controlId="formImage">
//             <Form.Label>Product Image</Form.Label>
//             <Form.Control
//               type="file"
//               accept="image/*"
//               onChange={handleImageChange}
//             />
//             {imagePreview && (
//               <div className="mt-3">
//                 <img src={imagePreview} alt="Selected" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
//                 <Button variant="danger" size="sm" onClick={handleRemoveImage}>Remove Image</Button>
//               </div>
//             )}
//           </Form.Group>

//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary" type="submit">
//             Save Changes
//           </Button>
//         </Form>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default EditProductModal;


import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { axiosInstance } from "../../utils/axios"; // Correct import for axios instance

const EditProductModal = ({ show, handleClose, productData, setProductData, handleSuccessUpdate }) => {
  const [editableProductData, setEditableProductData] = useState({}); // Local editable product data
  const [imagePreview, setImagePreview] = useState(null); // State for image preview

  useEffect(() => {
    if (productData) {
      // Initialize editable product data when modal opens
      setEditableProductData({
        ...productData,
      });

      // If the product has an image URL, set it as the preview image
      if (productData?.image) {
        setImagePreview(productData.image); // This assumes the image is a URL from the backend
      }
    }
  }, [productData, show]); // Re-run when productData or show changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableProductData((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditableProductData((prev) => ({ ...prev, image: file })); // Update editable data with selected file
      setImagePreview(URL.createObjectURL(file)); // Set image preview
    }
  };

  const handleRemoveImage = () => {
    setEditableProductData((prev) => ({ ...prev, imageUri: null })); // Remove image from editable data
    setImagePreview(null); // Remove image preview
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation before making API call
    if (!editableProductData.productName || !editableProductData.quantity || !editableProductData.description || !editableProductData.category || !editableProductData.price || !editableProductData.mrp) {
      toast.error('All fields except image are required');
      return;
    }

    // Prepare the form data (including image)
    try {
      const formData = new FormData();
      formData.append("productName", editableProductData.productName);
      formData.append("description", editableProductData.description);
      formData.append("price", editableProductData.price);
      formData.append("quantity", editableProductData.quantity);
      formData.append("category", editableProductData.category);
      formData.append("mrp", editableProductData.mrp);

      // Only append image if it's provided
      if (editableProductData.image) {
        formData.append("image", editableProductData.image);
      }

      // Make the PUT request to update the product
      const response = await axiosInstance.put(`/products/${editableProductData.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Use multipart/form-data for file upload
        },
      });

      if (response.status === 200) {
        toast.success("Product updated successfully!");
        handleSuccessUpdate(response.data); // Pass updated product data to parent via callback
        handleClose(); // Close modal on successful update
      }
    } catch (error) {
      toast.error("Error updating product!");
      console.error(error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formProductName">
            <Form.Label>Product Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product name"
              name="productName"
              value={editableProductData.productName || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product description"
              name="description"
              value={editableProductData.description || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formPrice">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter product price"
              name="price"
              value={editableProductData.price || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formQuantity">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter product quantity"
              name="quantity"
              value={editableProductData.quantity || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formCategory">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product category"
              name="category"
              value={editableProductData.category || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formMRP">
            <Form.Label>MRP</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter MRP"
              name="mrp"
              value={editableProductData.mrp || ""}
              onChange={handleChange}
            />
          </Form.Group>

          {/* Image upload section */}
          <Form.Group controlId="formImage">
            <Form.Label>Product Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            {/* If there's an image preview (either uploaded or URL) */}
            {imagePreview && (
              <div className="mt-3">
                <img
                  src={imagePreview}
                  alt="Selected"
                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                />
                <Button variant="danger" size="sm" onClick={handleRemoveImage}>Remove Image</Button>
              </div>
            )}
            {/* If there's an existing image URL, show it */}
            {!imagePreview && editableProductData?.imageUri && (
              <div className="mt-3">
                <img
                  src={editableProductData.imageUri}
                  alt="Product"
                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                />
                <Button variant="danger" size="sm" onClick={handleRemoveImage}>Remove Image</Button>
              </div>
            )}
          </Form.Group>

          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProductModal;
