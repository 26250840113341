
import React, { useState } from "react";
import { Col, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../app/features/cart/cartSlice";
import { FaMapMarkerAlt, FaPhoneAlt, FaShareAlt } from "react-icons/fa"; // Import the location icon
import "./ProductCard.css";

// import RegisterModal from "./RegisterModal";  // Import the RegisterModal
import UserRegisterModal from '../../components/user/userRegister';
// Helper function to handle share
const shareProduct = (productLink) => {
  const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(
    `Check out this product: ${productLink}`
  )}`;

  window.open(whatsappShareUrl, "_blank");
};


const ProductCard = ({ title, productItem, showEditDelete, onEdit, onDelete }) => {

  const buyer = useSelector((state) => state.buyer.buyer); // Access the buyer data from Redux
  console.log('buyer',buyer);

  const dispatch = useDispatch();
  const router = useNavigate();

  const [isModalVisible, setModalVisible] = useState(false);  // To control modal visibility
  const [isProductAdded, setProductAdded] = useState(false);  // To track if product is added to cart
  const [isMobileModalVisible, setMobileModalVisible] = useState(false);

  // Handle when product image or name is clicked
  const handelClick = () => {
    router(`/shop/${productItem.id}`, { state: { productItem } });  // Pass productItem via state
  };

  // Handle adding the product to the cart
  // Handle the user registration after clicking the "View Seller" button
  const handleViewSellerClick = () => {
    setModalVisible(true);  // Show the modal when the user clicks "View Seller"
  };

  // Close the modal
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  // Register the user (you can save the data to your backend here)
  const handleRegister = (name, mobile, email) => {
    // Here you can send the registration details to your backend
    console.log("User Registered", { name, mobile, email });
    toast.success("Successfully registered!");
  };

  const handleViewMobile = () => {
    setMobileModalVisible(true);
  };

  const handleCloseMobileModal = () => {
    setMobileModalVisible(false);
  };

  const handleShareClick = () => {
    const productLink = `https://www.ratefry.com/shop/${productItem.id}`;
    shareProduct(productLink);
  };


   // Google Maps URL for directions
   const getGoogleMapsUrl = (latitude, longitude) => {
    // Optionally, you can pass the user's location as origin if available.
    const userLatitude = buyer?.latitude;
    const userLongitude = buyer?.longitude;

    if (userLatitude && userLongitude) {
      return `https://www.google.com/maps/dir/?api=1&origin=${userLatitude},${userLongitude}&destination=${latitude},${longitude}`;
    }

    return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
  };

  return (
    <div className="product-card">
    <Col 
    key={productItem.id} 
    style={{borderColor:"white", borderWidth:2, margin:5,padding:5,borderRadius:5}}
    >
      {title === "Big Discount" ? (
        <span className="discount">{productItem.discount}% Off</span>
      ) : null}
      
      {/* Product Image */}
      <div
        className="cursor-pointer flex justify-center items-center"
        onClick={handelClick}
        
      >
  <img
    className="object-contain"
    loading="lazy"
    onResize="contain"
    src={productItem.imageUri}
    alt={productItem.productName}
    style={{height:150,width:300}}
  />
</div>

      
      {/* Heart Icon (Wishlist) */}

      {/* Product Details */}
      <div>
        <h4 onClick={handelClick} className="truncate cursor-pointer"> {productItem.productName}</h4>
        <h5 className="truncate cursor-pointer">{productItem?.electronicsUser?.firstName }</h5>
        <h6 className="truncate cursor-pointer">{productItem?.electronicsUser?.shopName }</h6>
        <h6 className="truncate cursor-pointer">{productItem?.electronicsUser?.city}</h6>
        {/* { productItem?.distance && <h6 className="truncate cursor-pointer">{productItem?.distance}:km</h6>} */}
        {productItem?.distance && !isNaN(Number(productItem?.distance)) && (
  <h6 className="truncate cursor-pointer">{Number(productItem?.distance)}:km</h6>
)}

        {/* Product Rating */}
        {/* <div className="rate">
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
        </div> */}
        
        {/* Price and Discount */}
        <div 
        // className="price"
        >
          <h4 className=" truncate">{productItem.price}</h4>          
        </div>
        {/* <SellerDetails
              shopName={productItem?.owner?.shopName || productItem?.electronicsUser ?.shopName}
              shopAddress={productItem?.owner?.shopAddress || productItem?.electronicsUser ?.shopName }
            /> */}

        {/* Product Description */}
        {/* <p className="product-description">{productItem?.description}</p> */}

        {/* Product MRP */}
        <div className="mrp  truncate">
          <span>MRP: {productItem?.mrp}</span>
        </div>

        {/* Owner Details (Optional) */}
        <div className="owner-info">
          {/* <span>Owner ID: {productItem?.owner?.id}</span>
          <div>Location: {productItem?.owner?.latitude}, {productItem?.owner?.longitude}</div> */}
        </div>

         {/* Shop Address with Location Icon */}
         {/* {productItem?.owner?.latitude || productItem?.electronicsUser?.latitude || productItem?.owner?.longitude || productItem?.electronicsUser?.longitude && ( */}
          <div 
          // className="shop-address"
          >
            <a
              href={getGoogleMapsUrl(productItem?.electronicsUser?.latitude , productItem?.electronicsUser?.longitude)}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center  justify-center  text-blue-500  truncate"
            >
              <FaMapMarkerAlt className="mr-2 " /> {productItem?.electronicsUser?.shopAddress}
            </a>
          </div>
        {/* // )} */}

 {/* Share Button */}
 <div className="flex  items-center justify-center flex-row  space-x-4">
  {/* Share Button */}
  <button
    className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white py-1 px-1 mt-2 rounded-md transition-all duration-200"
    onClick={handleShareClick}
  >
    <FaShareAlt className="mr-2" /> Share
  </button>

  {/* View Mobile Number Button */}
  <button
    className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white py-1 px-1 mt-2 rounded-md transition-all duration-200"
    onClick={handleViewMobile}
  >
    <FaPhoneAlt className="mr-2" /> Mobile
  </button>
</div>

      

        {/* Modal for viewing mobile number */}
        <Modal
          show={isMobileModalVisible}
          onHide={handleCloseMobileModal}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Seller Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-lg">Mobile Number: {productItem?.electronicsUser?.mobile || "Not available"}</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-3 rounded-md transition duration-200"
              onClick={handleCloseMobileModal}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
    


        {/* Show Edit and Delete options if showEditDelete is true */}
        {showEditDelete && (
          <div className="edit-delete-actions">
            <Button variant="warning" onClick={() => onEdit(productItem)}>Edit</Button>
            <Button variant="danger" onClick={() => onDelete(productItem.id)}>Delete</Button>
          </div>
        )}
      </div>

      {/* Show modal if visible */}
      <UserRegisterModal
        showModal={isModalVisible} 
        onClose={handleCloseModal} 
        onRegister={handleRegister}
      />
    </Col>
    </div>
  );
};

export default ProductCard;







// import React, { useState } from "react";
// import { Col, Modal } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { FaMapMarkerAlt, FaShareAlt, FaPhoneAlt } from "react-icons/fa";
// import "./ProductCard.css";

// // Helper function to handle share
// const shareProduct = (productLink) => {
//   const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(
//     `Check out this product: ${productLink}`
//   )}`;

//   window.open(whatsappShareUrl, "_blank");
// };

// const ProductCard = ({ title, productItem, showEditDelete, onEdit, onDelete }) => {
//   const [isMobileModalVisible, setMobileModalVisible] = useState(false);

//   const handleViewMobile = () => {
//     setMobileModalVisible(true);
//   };

//   const handleCloseMobileModal = () => {
//     setMobileModalVisible(false);
//   };

//   const handleShareClick = () => {
//     const productLink = `https://yourwebsite.com/product/${productItem.id}`;
//     shareProduct(productLink);
//   };

//   return (
//     <div className="product-card">
//       <Col
//         key={productItem.id}
//         style={{ borderColor: "white", borderWidth: 2, margin: 5, padding: 5, borderRadius: 5 }}
//       >
//         {/* Product Image */}
//         <div
//           className="cursor-pointer flex justify-center items-center"
//           onClick={() => window.location.href = `/shop/${productItem.id}`}
//         >
//           <img
//             className="object-contain"
//             src={productItem.imageUri}
//             alt={productItem.productName}
//             style={{ height: 200, width: 300 }}
//           />
//         </div>

//         <div className="mt-2">
//           {/* Product Name */}
//           <h4 className="truncate cursor-pointer text-lg font-semibold">{productItem.productName}</h4>
//           <h5 className="truncate cursor-pointer text-sm text-gray-500">{productItem?.ProductCategory?.categoryName}</h5>

//           {/* Share Button */}
//           <button
//             className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white py-2 px-3 mt-2 rounded-md transition-all duration-200"
//             onClick={handleShareClick}
//           >
//             <FaShareAlt className="mr-2" /> Share
//           </button>

//           {/* View Mobile Number Button */}
//           <button
//             className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white py-2 px-3 mt-2 rounded-md transition-all duration-200"
//             onClick={handleViewMobile}
//           >
//             <FaPhoneAlt className="mr-2" /> View Mobile
//           </button>
//         </div>

//         {/* Modal for viewing mobile number */}
//         <Modal
//           show={isMobileModalVisible}
//           onHide={handleCloseMobileModal}
//           centered
//         >
//           <Modal.Header closeButton>
//             <Modal.Title>Seller Contact</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <p className="text-lg">Mobile Number: {productItem?.owner?.mobile || "Not available"}</p>
//           </Modal.Body>
//           <Modal.Footer>
//             <button
//               className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-3 rounded-md transition duration-200"
//               onClick={handleCloseMobileModal}
//             >
//               Close
//             </button>
//           </Modal.Footer>
//         </Modal>
//       </Col>
//     </div>
//   );
// };

// export default ProductCard;
