// import React, { useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate

// import { axiosInstance } from '../utils/axios';
// import { toast } from 'react-toastify';
// import { useDispatch } from 'react-redux';
// import { setToken } from '../app/features/login/authSlice';
// import { setBuyer } from '../app/features/login/buyerSlice';

// const AdminLoginComponent = () => {
//     const navigate = useNavigate();

//     const dispatch = useDispatch();

//   // State for mobile/email and password
//   const [emailOrMobile, setEmailOrMobile] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const [loading, setLoading] = useState(false);
//   // const [token, setToken] = useState('');

//   // Handle input change
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === 'emailOrMobile') {
//       setEmailOrMobile(value);
//     } else if (name === 'password') {
//       setPassword(value);
//     }
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError('');

//     try {
//       // Prepare the login data
//       const loginData = { mobile: emailOrMobile,password };

//       // Send login request to the server
//       const response = await axiosInstance.post('/admin/login', loginData);
//       // Handle success (assuming JWT is returned)
//       if (response?.data?.status) {
//         setToken(response.data.token); // Save the token (or save to localStorage)
//         localStorage.setItem('token', response.data.token);
//         dispatch(setToken(response.data.token));
//             localStorage.setItem('buyer', 'admin');
//             dispatch(setBuyer('admin'));
//          // Redirect to the home page after successful login
//          navigate('/admin/dashboard'); // This will redirect to the home page
//         toast.success(response.data.message);
//         window.location.reload();
//         setLoading(false);
//       }
//     }
//     catch (error) {
//         setError('Invalid login credentials');
//         setLoading(false);
//         console.error('Error submitting form:', error);
//         // Check if error response has a message
//         if (error.response && error.response.data && error.response.data.message) {
//           // Show the message from backend error response
//           alert(error.response.data.message);
//         } else {
//           // Default error message
//           alert('An unexpected error occurred. Please try again.');
//         }
//   };
// }

//   return (
//     <div className="flex justify-center items-center h-screen bg-gray-50">
//       <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-md">
//         <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">Admin Login</h2>
//         <form onSubmit={handleSubmit} className="space-y-4">
//           {/* Email or Mobile */}
//           <div>
//             <label htmlFor="emailOrMobile" className="block text-sm font-medium text-gray-700">
//               Email or Mobile Number
//             </label>
//             <input
//               type="text"
//               name="emailOrMobile"
//               id="emailOrMobile"
//               value={emailOrMobile}
//               onChange={handleChange}
//               required
//               className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//               placeholder="Enter your email or mobile number"
//             />
//           </div>

//           {/* Password */}
//           <div>
//             <label htmlFor="password" className="block text-sm font-medium text-gray-700">
//               Password
//             </label>
//             <input
//               type="password"
//               name="password"
//               id="password"
//               value={password}
//               onChange={handleChange}
//               required
//               className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//               placeholder="Enter your password"
//             />
//           </div>

//           {/* Error Message */}
//           {error && <p className="text-red-500 text-sm">{error}</p>}

//           {/* Submit Button */}
//           <div>
//             <button
//               type="submit"
//               disabled={loading}
//               className={`w-full py-2 mt-4 bg-blue-600 text-white rounded-md focus:outline-none ${
//                 loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
//               }`}
//             >
//               {loading ? 'Logging in...' : 'Login'}
//             </button>
//           </div>
//         </form>

//         <div className="mt-4 text-center">
//           <span className="text-sm text-gray-600">
//             Don't have an account?{' '}
//             <a href="/signup" className="text-blue-600 hover:underline">
//               Sign up
//             </a>
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdminLoginComponent;



import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import { axiosInstance } from '../utils/axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setToken } from '../app/features/login/authSlice';
import { setBuyer } from '../app/features/login/buyerSlice';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import React Icons for showing/hiding password

const AdminLoginComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // State for mobile/email and password
    const [emailOrMobile, setEmailOrMobile] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'emailOrMobile') {
            setEmailOrMobile(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            // Prepare the login data
            const loginData = { mobile: emailOrMobile, password };

            // Send login request to the server
            const response = await axiosInstance.post('/admin/login', loginData);
            // Handle success (assuming JWT is returned)
            if (response?.data?.status) {
                setToken(response.data.token); // Save the token (or save to localStorage)
                localStorage.setItem('token', response.data.token);
                dispatch(setToken(response.data.token));
                localStorage.setItem('buyer', 'admin');
                dispatch(setBuyer('admin'));
                // Redirect to the home page after successful login
                navigate('/admin/dashboard'); // This will redirect to the home page
                toast.success(response.data.message);
                window.location.reload();
                setLoading(false);
            }
        } catch (error) {
            setError('Invalid login credentials');
            setLoading(false);
            console.error('Error submitting form:', error);
            // Check if error response has a message
            if (error.response && error.response.data && error.response.data.message) {
                // Show the message from backend error response
                alert(error.response.data.message);
            } else {
                // Default error message
                alert('An unexpected error occurred. Please try again.');
            }
        }
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-50">
            <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">Admin Login</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    {/* Email or Mobile */}
                    <div>
                        <label htmlFor="emailOrMobile" className="block text-sm font-medium text-gray-700">
                            Email or Mobile Number
                        </label>
                        <input
                            type="text"
                            name="emailOrMobile"
                            id="emailOrMobile"
                            value={emailOrMobile}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your email or mobile number"
                        />
                    </div>

                    {/* Password */}
                    <div className="relative">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Password
                        </label>
                        <input
                            type={showPassword ? 'text' : 'password'} // Toggle password type
                            name="password"
                            id="password"
                            value={password}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your password"
                        />
                        {/* Eye Icon for Show/Hide Password */}
                        <div
                            onClick={togglePasswordVisibility}
                            className="absolute right-3 top-12 transform -translate-y-1/2 cursor-pointer"
                        >
                            {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                        </div>
                    </div>

                    {/* Error Message */}
                    {error && <p className="text-red-500 text-sm">{error}</p>}

                    {/* Submit Button */}
                    <div>
                        <button
                            type="submit"
                            disabled={loading}
                            className={`w-full py-2 mt-4 bg-[#e2f2b2] hover:text-white rounded-md focus:outline-none ${
                                loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#4ED31A]'
                            }`}
                        >
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                    </div>
                </form>

                <div className="mt-4 text-center">
                    {/* <span className="text-sm text-gray-600">
                        Don't have an account?{' '}
                        <a href="/signup" className="text-blue-600 hover:underline">
                            Sign up
                        </a>
                    </span> */}
                </div>
            </div>
        </div>
    );
};

export default AdminLoginComponent;
