
import { axiosInstance } from "./axios";

export const getSingleAdminDetails = async () => {
    const response = await axiosInstance.get(`/admin/get/by/token`);
    return response.data;
  };


  export const getSingleBuyerDetails = async () => {
    const response = await axiosInstance.get(`/buyer/get/by/token`);
    return response.data;
  };


  export const getProductDetailsById = async (id) => {
    try{
    const response = await axiosInstance.get(`/products/${id}`);
    return response.data;
    }catch(err){
           console.log('err in single product get ',err)
    }
  };


  
  

  export const updateSingleBuyerDetails = async (editableProduct) => {
    const response = await axiosInstance.put(`/buyer/update/by/token`, editableProduct, {
      headers: {
        "Content-Type": "multipart/form-data", // Use multipart/form-data for file upload
      },
    });
    return response.data;
  };
  

  export const updateSingleAdminDetails = async (editableProduct) => {
    const response = await axiosInstance.put(`/admin/update/by/token`, editableProduct, {
      headers: {
        "Content-Type": "multipart/form-data", // Use multipart/form-data for file upload
      },
    });
    return response.data;
  };
  



  

