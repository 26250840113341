
// import React from "react"
// import "./style.css"
// import { Col, Container, Row } from "react-bootstrap"
// import { useNavigate } from "react-router-dom"

// const Footer = () => {

//   const navigate = useNavigate();

//   const navigatePage = (page)=>{
//      navigate(page);
//      // Scroll the page to the top
//     window.scrollTo(0, 0);

//   }


//   return (
//     <footer>
//         <Container>
//           <Row className="footer-row">
//             <Col md={3} sm={5} className='box'>
//               <div className="logo">
//                   <ion-icon name="bag"></ion-icon>
//                   <h1>Multimart</h1>
//               </div>
//               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor libero id et, in gravida. Sit diam duis mauris nulla cursus. Erat et lectus vel ut sollicitudin elit at amet.</p>
//             </Col>
//             <Col md={3} sm={5} className='box'>
//               <h2>About Us</h2>
//               <ul>
//                 <li>Careers</li>
//                 <li>Our Stores</li>
//                 <li>Our Cares</li>
//                 <li onClick={()=>navigatePage('/terms-conditions')}>Terms & Conditions</li>
//                 <li>Privacy Policy</li>
//               </ul>
//             </Col>
//             <Col md={3} sm={5} className='box'>
//               <h2>Customer Care</h2>
//               <ul>
//                 <li>Help Center </li>
//                 <li>How to Buy </li>
//                 <li>Track Your Order </li>
//                 <li>Corporate & Bulk Purchasing </li>
//                 <li>Returns & Refunds </li>
//               </ul>
//             </Col>
//             <Col md={3} sm={5} className='box'>
//               <h2>Contact Us</h2>
//               <ul>
//                 <li>70 Washington Square South, New York, NY 10012, United States </li>
//                 <li>Email: uilib.help@gmail.com</li>
//                 <li>Phone: +1 1123 456 780</li>
//               </ul>
//             </Col>
//           </Row>
//           <Col>
//           <li>
        
//           </li>
//           <div className="logo">
//                   <h1>  Powered by Dosso21 services pvt ltd</h1>
//               </div>
//           </Col>
//         </Container>
//     </footer>
//   )
// }

// export default Footer




// import React from "react";
// import "./style.css";
// import { Col, Container, Row } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

// const Footer = () => {
//   const navigate = useNavigate();

//   const navigatePage = (page) => {
//     navigate(page);
//     // Scroll the page to the top
//     window.scrollTo(0, 0);
//   };

//   return (
//     <footer>
//       <Container>
//         <Row className="footer-row">
//           <Col md={3} sm={5} className="box">
//             <div className="logo">
//               <ion-icon name="bag"></ion-icon>
//               <h1>Multimart</h1>
//             </div>
//             <p>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor
//               libero id et, in gravida. Sit diam duis mauris nulla cursus. Erat
//               et lectus vel ut sollicitudin elit at amet.
//             </p>
//           </Col>

//           <Col md={3} sm={5} className="box">
//             <h2>About Us</h2>
//             <ul>
//               {/* Only keep Terms & Conditions */}
//               <li onClick={() => navigatePage("/terms-conditions")}>
//                 Terms & Conditions
//               </li>
//             </ul>
//           </Col>

//           {/* Commenting out the other sections */}
//           {/* <Col md={3} sm={5} className='box'>
//             <h2>Customer Care</h2>
//             <ul>
//               <li>Help Center</li>
//               <li>How to Buy</li>
//               <li>Track Your Order</li>
//               <li>Corporate & Bulk Purchasing</li>
//               <li>Returns & Refunds</li>
//             </ul>
//           </Col>
//           <Col md={3} sm={5} className='box'>
//             <h2>Contact Us</h2>
//             <ul>
//               <li>70 Washington Square South, New York, NY 10012, United States</li>
//               <li>Email: uilib.help@gmail.com</li>
//               <li>Phone: +1 1123 456 780</li>
//             </ul>
//           </Col> */}
//         </Row>

//         {/* Moving Powered by text to the center */}
//         <Row className="justify-content-center">
//           <Col className="text-center">
//             <div className="logo">
//               <h1>Powered by Dosso21 Services Pvt Ltd</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </footer>
//   );
// };

// export default Footer;


import React from "react";
import { Col, Container, Row } from "react-bootstrap"; // Bootstrap for layout (optional)
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const navigatePage = (page) => {
    navigate(page);
    // Scroll the page to the top
    window.scrollTo(0, 0);
  };

  return (
    <footer className="bg-[#0f3460] py-12">
      <Container>
        <Row className="footer-row">
          <Col md={3} sm={5} className="box">
            <div className="logo flex items-center">
              {/* <ion-icon name="bag" className="text-xl"></ion-icon> */}
              <h1 className="text-2xl font-bold ml-2 text-white">Ratefry</h1>
            </div>
            <p className="text-sm text-white mt-4">
            At Ratefry, you can easily check the prices of any product you’re interested in. 
            Our platform allows you to compare rates from various stores, 
            helping you make informed purchasing decisions.
            </p>
          </Col>

          <Col md={3} sm={5} className="box">
            {/* <h2 className="text-xl font-semibold">About Us</h2> */}
            <ul className="mt-4 space-y-2">
              {/* Only keep Terms & Conditions */}
              <li
                onClick={() => navigatePage("/terms-conditions")}
                className="text-white cursor-pointer hover:underline "
              >
                Terms & Conditions
              </li>
            </ul>
          </Col>
        </Row>

        {/* Moving Powered by text to the center */}
        <Row className="justify-center mt-8">
          <Col className="text-center">
            <div className="logo">
              <h1 className="text-lg font-semibold text-white">Powered by Dosso21 Services Pvt Ltd</h1>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
